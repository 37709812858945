import { GameElement } from "./robboGameElement";
import { randRange } from "./robboGame";

export class ServSuprise extends GameElement {

    name = "Suprise";

    tabChar = [];
    constructor(/*Class.RobboGame*/robboCore){

        super(robboCore);

        var Block = this.block;
        this.tabChar = [
            Block.CHAR_BOMBA,
            Block.CHAR_ADD_LIVE,
            Block.CHAR_BOOM_1,
            Block.CHAR_CANNON_DOWN,
            Block.CHAR_CANNON_UP,
            Block.CHAR_SCREW,
            Block.CHAR_CANNON_LEFT,
            Block.CHAR_CANNON_RIGHT,
            Block.CHAR_KEY,
            Block.CHAR_ADD_LIVE,
            Block.CHAR_BOX,
            Block.CHAR_SLIDE_BOX,
            Block.CHAR_ML_RIGHT_A,
            Block.CHAR_MR_RIGHT_A,
            Block.CHAR_BULLET,
            Block.CHAR_SURPRISE_BOOM1,
            Block.CHAR_BIRD_DOWN_A,
            Block.CHAR_BIRD_LEFT_A,
            Block.CHAR_BIRD_RIGHT_A,
            Block.CHAR_BIRD_UP_A,
            Block.CHAR_SCREW,
            Block.CHAR_ADD_LIVE,
            Block.CHAR_GROUND,
            Block.CHAR_BULLET,
            Block.CHAR_FIRE_BIRD_LEFT_A,
            Block.CHAR_FIRE_BIRD_RIGHT_A,
            Block.CHAR_ROTATE_CANNON_DOWN,
            Block.CHAR_MOVING_CANNON_L_A,
            Block.CHAR_MOVING_CANNON_R_A,
            Block.CHAR_SCREW,
            Block.CHAR_SURPRISE,
            Block.CHAR_SURPRISE_BOOM1,
            Block.CHAR_LONG_CANNON_LEFT,
            Block.CHAR_LONG_CANNON_RIGHT,
            Block.CHAR_LONG_CANNON_UP,
            Block.CHAR_LONG_CANNON_DOWN,
            Block.CHAR_MOVING_EYES_A,
            Block.CHAR_ADD_LIVE,
            Block.CHAR_BULLET
        ];
    }

    serv(bx:number, by:number) {
        var i = randRange(0, this.tabChar.length-1);
        this.cave.setBlock(this.tabChar[i], bx, by);
    }
}

