import { GameElement } from "./robboGameElement";

export class ServMonsterLeft extends GameElement {

    name = "Monster Left";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore)
    }

    serv(bx, by) {

        var caveChar = this.caveChar,
            CAVE_WIDTH = this.CAVE_WIDTH,
            STOP_BIT = this.STOP_BIT,
            Block = this.block;

        // TRZYMA SIĘ DOŁU I IDZIE W PRAWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ML_RIGHT_A)
        {
            // CZY MA COŚ POD SPODEM
            if(this.getBit(bx, by+1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by+1)*CAVE_WIDTH] = Block.CHAR_ML_DOWN_A+STOP_BIT;
                return;
            }

            // CZY MOŻE SIĘ PRZESUNĄĆ W PRAWO
            if(this.getBit(bx+1, by) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+1+by*CAVE_WIDTH] = Block.CHAR_ML_RIGHT_A+STOP_BIT;
                return;
            }

            // JEST PRZESZKODA, IDZIE W GÓRĘ
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_ML_UP_A;
            return;
        }

        // TRZYMA SIĘ LEWEJ I IDZIE W DÓŁ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ML_DOWN_A)
        {
            // CZY MA COŚ PO LEWEJ
            if(this.getBit(bx-1, by) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx-1+by*CAVE_WIDTH] = Block.CHAR_ML_LEFT_A;
                return;
            }

            // CZY MOŻE SIĘ PRZESUNĄĆ W DÓŁ
            if(this.getBit(bx, by+1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by+1)*CAVE_WIDTH] = Block.CHAR_ML_DOWN_A+STOP_BIT;
                return;
            }

            // PRZESZKODA, IDZIE W PRAWO
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_ML_RIGHT_A;
            return;
        }

        // TRZYMA SIĘ GÓRY I IDZIE W LEWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ML_LEFT_A)
        {
            // CZY MA COŚ W GÓRZE
            if(this.getBit(bx, by-1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by-1)*CAVE_WIDTH] = Block.CHAR_ML_UP_A;
                return;
            }

            // CZY MOŻE SIE PRZESUNĄĆ W LEWO
            if(this.getBit(bx-1, by) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH]=0;
                caveChar[bx-1+by*CAVE_WIDTH] = Block.CHAR_ML_LEFT_A;
                return;
            }

            // PRZESZKODA, IDZIE W DÓŁ
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_ML_DOWN_A;
            return;
        }

        // TRZYMA SIE PRAWEJ, IDZIE W GÓRĘ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ML_UP_A)
        {
            // CZY MA COŚ PO PRAWEJ
            if(this.getBit(bx+1, by) == 0)
            {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                caveChar[bx+1 + by * CAVE_WIDTH] = Block.CHAR_ML_RIGHT_A+STOP_BIT;
                return;
            }

            // CZY MOŻE SIĘ PRZESUNĄĆ W GÓRĘ
            if(this.getBit(bx, by-1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by-1)*CAVE_WIDTH] = Block.CHAR_ML_UP_A;
                return;
            }

            // PRZESZKODA, IDZIE W LEWO
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_ML_LEFT_A;
        }
    }
}

