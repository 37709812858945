export class GameObject {

    public name = "GameObject";

    public constructor() {
        // console.log('konstruktor _Object');
    };

    public toString() {
        return this.name;
    }
}