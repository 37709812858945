import { GameElement } from "./robboGameElement";
import { randRange } from "./robboGame";

export class ServCannon extends GameElement {

    name = "Cannon";

    constructor(/*Class.RobboGame*/robboCore, /*ServProjectile*/servStrzal){
        super(robboCore);
        this.servStrzal = servStrzal;
    }

    /*ServProjectile*/ servStrzal = null;

    serv(bx, by, canMove) {

        var caveChar = this.caveChar,
            Block = this.block,
            CAVE_WIDTH = this.CAVE_WIDTH;

        // LEWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_CANNON_LEFT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx-1, by, Block.CHAR_PROJECTILE_LEFT_A);
            return false;
        }

        // PRAWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_CANNON_RIGHT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx+1, by, Block.CHAR_PROJECTILE_RIGHT_A);
            return false;
        }

        // GÓRA
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_CANNON_UP) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by-1, Block.CHAR_PROJECTILE_UP_A);
            return false;
        }

        // DÓŁ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_CANNON_DOWN) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by+1, Block.CHAR_PROJECTILE_DOWN_A);
            return false;
        }

        /////////////////////////////////// OBROTOWE
        // LEWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ROTATE_CANNON_LEFT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx-1, by, Block.CHAR_PROJECTILE_LEFT_A);
            this.randNewDirection(bx, by);
            return false;
        }

        // PRAWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ROTATE_CANNON_RIGHT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx+1, by, Block.CHAR_PROJECTILE_RIGHT_A);
            this.randNewDirection(bx, by);
            return false;
        }

        // GÓRA
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ROTATE_CANNON_UP) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by-1, Block.CHAR_PROJECTILE_UP_A);
            this.randNewDirection(bx, by);
            return false;
        }

        // DÓŁ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_ROTATE_CANNON_DOWN) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by+1, Block.CHAR_PROJECTILE_DOWN_A);
            this.randNewDirection(bx, by);
            return false;
        }

        if (canMove) {
            /////////////////////////////////// CHODZĄCE
            // LEWO
            if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_MOVING_CANNON_L_A) {
                if (this.getBit(bx - 1, by) != 0)
                    caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_MOVING_CANNON_R_A;
                else {
                    caveChar[bx + by * CAVE_WIDTH] = 0;
                    caveChar[bx - 1 + by * CAVE_WIDTH] = Block.CHAR_MOVING_CANNON_L_A;
                    bx--;
                }
                if (this.randFire())
                    return this.servStrzal.startShoot(bx, by - 1, Block.CHAR_PROJECTILE_UP_A);
                return false;
            }

            // PRAWO
            if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_MOVING_CANNON_R_A) {
                if (this.getBit(bx + 1, by) != 0)
                    caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_MOVING_CANNON_L_A;
                else {
                    caveChar[bx + by * CAVE_WIDTH] = 0;
                    caveChar[bx + 1 + by * CAVE_WIDTH] = Block.CHAR_MOVING_CANNON_R_A + this.STOP_BIT;
                    bx++;
                }
                if (this.randFire())
                    return this.servStrzal.startShoot(bx, by - 1, Block.CHAR_PROJECTILE_UP_A);
                return false;
            }

            /*    if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_DZIALKO_MOVE_R_B)
             caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_DZIALKO_MOVE_R_A;
             if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_DZIALKO_MOVE_L_B)
             caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_DZIALKO_MOVE_L_A;*/
        }

        /////////////////////////////// DZIAŁKO STRZELAJĄCE CIĄGŁYM
        // W PRAWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_LONG_CANNON_RIGHT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx+1, by, Block.CHAR_LONG_PROJECTILE_RIGHT_A);
            return false;
        }
        // W LEWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_LONG_CANNON_LEFT) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx-1, by, Block.CHAR_LONG_PROJECTILE_LEFT_A);
            return false;
        }
        // W GÓRĘ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_LONG_CANNON_UP) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by-1, Block.CHAR_LONG_PROJECTILE_UP_A);
            return false;
        }
        // W DÓŁ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_LONG_CANNON_DOWN) {
            if(this.randFire())
                return this.servStrzal.startShoot(bx, by+1, Block.CHAR_LONG_PROJECTILE_DOWN_A);
            return false;
        }

        /////////////////////////////// BLASTER
        // W PRAWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_BLAST_CANNON_RIGHT) {
            if(this.randFire())
                return this.servStrzal.fireBlast(bx+1, by, Block.CHAR_BLAST_PROJECTILE_RIGHT);
            return false;
        }
        // W LEWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_BLAST_CANNON_LEFT) {
            if(this.randFire())
                return this.servStrzal.fireBlast(bx-1, by, Block.CHAR_BLAST_PROJECTILE_LEFT);
            return false;
        }
        // W GÓRĘ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_BLAST_CANNON_UP) {
            if(this.randFire())
                return this.servStrzal.fireBlast(bx, by-1, Block.CHAR_BLAST_PROJECTILE_UP);
            return false;
        }
        // W DÓŁ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_BLAST_CANNON_DOWN) {
            if(this.randFire())
                return this.servStrzal.fireBlast(bx, by+1, Block.CHAR_BLAST_PROJECTILE_DOWN);
            return false;
        }
        return false;
    }


    /**
     * setting new direction for rotating cannon
     * */
    randNewDirection(bx, by) {
        if(!this.randFire()) return;

        var i = Math.abs(randRange(0,3));
        switch(i) {
            case 0:
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_ROTATE_CANNON_DOWN;
                break;
            case 1:
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_ROTATE_CANNON_UP;
                break;
            case 2:
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_ROTATE_CANNON_LEFT;
                break;
            default:
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_ROTATE_CANNON_RIGHT;
                break;
        }
    }

    randFire() {
        return randRange(0, 10) == 1;
    }
}