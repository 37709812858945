import { GameObject } from "./game-object";

export class GameElement extends GameObject {

    name = "Game Element";

    CAVE_WIDTH  = 0;
    CAVE_HEIGHT = 0;
    robboCore   = null;
    cave        = null;
    caveChar    = [];
    STOP_BIT    = 0; // !!! Block.STOP_BIT;
    block       = null;

    constructor(/*Class.RobboGame*/robboCore){
        super();
        this.STOP_BIT = robboCore.block.STOP_BIT;
        this.robboCore = robboCore;
        this.cave = robboCore.level;
        this.caveChar = robboCore.level.currentLevelData;
        this.CAVE_WIDTH = this.cave.levelWidth
        this.CAVE_HEIGHT= this.cave.levelHeight;
        this.block = robboCore.block;
    }

    /** return bit mask of block on position x, y */
    getBit(x, y) {
        return this.block.blocksMask[this.cave.currentLevelData[x + y * this.CAVE_WIDTH] & 127];
    }

    /** check for highest bit used to flag blocks that have been moved in current "game tick" and should be skipped if found again */
    isHighestBitSet(x, y) {
        return  (this.cave.currentLevelData[x + y * this.CAVE_WIDTH] & this.STOP_BIT) == this.STOP_BIT;
    }

    /** checking if is robbo around - left, right, top, bottom */
    checkLRTB(bx, by) {
        if((this.getBit(bx-1, by) & this.block.B_ROBBO) == this.block.B_ROBBO) return true;
        if((this.getBit(bx+1, by) & this.block.B_ROBBO) == this.block.B_ROBBO) return true;
        if((this.getBit(bx, by-1) & this.block.B_ROBBO) == this.block.B_ROBBO) return true;
        if((this.getBit(bx, by+1) & this.block.B_ROBBO) == this.block.B_ROBBO) return true;
        return false;
    }

    checkBitMaskLR(bx, by, mask) {
        if((this.getBit(bx-1, by) & mask) == mask) return true;
        if((this.getBit(bx+1, by) & mask) == mask) return true;
        return false;
    }

    checkBitMaskTB(bx, by, mask) {
        if((this.getBit(bx, by-1) & mask) == mask) return true;
        if((this.getBit(bx, by+1) & mask) == mask) return true;
        return false;
    }

    checkBitMaskLRTB(bx, by, mask) {
        if((this.getBit(bx-1, by) & mask) == mask) return true;
        if((this.getBit(bx+1, by) & mask) == mask) return true;
        if((this.getBit(bx, by-1) & mask) == mask) return true;
        if((this.getBit(bx, by+1) & mask) == mask) return true;
        return false;
    }
}