// let howler = require('howler');
declare var Howl: any;

let sounds = [
    "bomb",
    "bullets",
    "cannon-fire",
    "door",
    "fireend",
    "fireend2",
    "key",
    "live",
    "mirror",
    "push",
    "screw",
    "start-level",
    "step"
];

let soundsBuffer = {};

export let soundManager = {
    play: function(name) {
        if (soundsBuffer[name])
            soundsBuffer[name].play();
    },
    load: (folder, clbck) => loadSounds(folder, clbck),
    destroy: () => {
        for (let i in sounds) {
            let name = sounds[i];
            soundsBuffer[name].unload();
        }
    }
}

function loadSounds(folder, clbck) {
    let counter = 0;
    for (let i in sounds) {
        counter++;
        let name = sounds[i];
        soundsBuffer[name] = new Howl({
            src: folder+'sfx/'+name+'.mp3'
        })
        soundsBuffer[name].once('load', ()=>{
            console.log(folder+'sfx/'+name+'.mp3 loaded');
            counter--;
            if (!counter) clbck();
        });
    }
}
