import { GameObject } from "./game-object";
import { RobboGame } from "./robboGame";
import { RobboBlock } from "./robboBlock";
import { Label } from "./ui/label";
import { Stage } from "./ui/stage";
import { Layer } from "./ui/layer";

import { RobboLevel } from "./robboLevel";
import { soundManager } from "./sfx";
import { Overlay } from "./ui/overlay";

export var robbo = {
    levels: [],//RobboLevels, RobboLevels2, CejsoftLevels, BossLevels, Levels653924, AnticSoftLevels, JanusoftLevels, RobbocopLevels],
    block: <RobboBlock> null,
    game: <Game> null,
    level: <RobboLevel> null
};

var log = console.log;
declare var $:any;

robbo.block = new RobboBlock();

export class Game extends GameObject {

    name = "Robbo game";

    isGameRuning = false;

    // used to calculate fps
    fps = 0;
    _timePerFrame = 0;
    _timeAfterMainLoop = 0;
    _mainLoopCounter = 0;    // count how many times game loop was called
    _beforeDrawCallback:Function = void 0;
    _afterDrawCallback:Function = void 0;

    stage:Stage = void 0;
    menuLayer:Layer = void 0;
    levelsPickerLayer:Layer = void 0;

    containerId: string;
    public containerEl = void 0;
    width = 0;
    height = 0;
    context = void 0;

    debugLayer:Layer = void 0;
    _debugFpsLabel:Label = void 0;

    date: any;

    imgAssetsSrc = {
        tiles:  'gfx/tiles-transparent.png',
        hud:    'gfx/robbo-hud.png',
        digits: 'gfx/digits.png',
        niepokoj: 'gfx/niepokoj-com.png',
        title:  'gfx/title.gif'
    };
    imgAssets:any;
    imgAssetsReady = false;

    robboGame:RobboGame;

    rootFolder:string = "";
    lostFocusLabel:Label;
    overlay:Overlay;
    testLevelData:number[];

    gameIsReady = false;
    debug = false;

    constructor ({containerId, width, height, levels, testLevelData, rootFolder = "", debug=false} : {containerId:string, testLevelData:number[], width:number, height:number, levels:string[], rootFolder:string, debug:boolean}) {

        super();
        var self = this;
        robbo.game = this;
        this.date = new Date();
        this.width = width;
        this.height = height;
        this.rootFolder = rootFolder;
        this.testLevelData = testLevelData;
        this.debug = debug;
        
        // checking if game has focus or not - need to prevent keys to prevent move entire page with arrows up/down/space
        $(document).click(e => {
            if (!this.gameIsReady) return;
            // if the target of the click isn't the container nor a descendant of the container
            if (!$(this.containerEl).is(e.target) && $(this.containerEl).has(e.target).length === 0) {
                this.robboGame.isPreventDefault = false;
                this.dettachEvents();
            }
            else {
                this.robboGame.isPreventDefault = true;
                this.attachEvents();
            }
        });

        this.containerId = containerId;
        this.containerEl = document.getElementById(containerId);
        this.context = this.containerEl.getContext('2d');
        
        this.stage = new Stage("robbojs");
        // this.stage = new Stage(containerId, width, height, "robbojs");
        this.setFPS(16);

        this.menuLayer = new Layer('menu');
        this.stage.addLayer(this.menuLayer);
        this.levelsPickerLayer = new Layer('level picker');
        this.levelsPickerLayer.visible = false;
        this.stage.addLayer(this.levelsPickerLayer);

        this.debugLayer = new Layer('debug');
        this._debugFpsLabel = new Label("FPS", 1, 50, "white");
        this.debugLayer.add(this._debugFpsLabel);
        this.stage.addLayer(this.debugLayer);

        let verLabel:Label = new Label("JS Ver 1.0", 230, 2, "white");
        this.stage.addLayer(verLabel);

        this.lostFocusLabel = this.createMenuLabel("Click here to continue", this.width/2, 100, "white");
        this.lostFocusLabel.bevelColor = "black";
        // this.lostFocusLabel.bevelWidth = 3;

        this.overlay = new Overlay("black", width, height);

        this.showLoading();
        this.loadImageAssets(this.imgAssetsSrc, images => {
            log('images loaded');
            this.imgAssets = images;
            this.imgAssetsReady = true;

            // self.robboGame.setQuitGameCallback(null); /* game doesn't quit to menu */

            this.loadLevels(levels, levels => {
                soundManager.load(this.rootFolder, ()=>{

                    this.setupMenu();
                    this.robboGame = new RobboGame(this, width, height-64);
                    this.robboGame.setQuitGameCallback(() => {
                        this.robboGame.startDemo();
                        this.showMenu();
                    });
                    this.robboGame.levelPassedCallback = (stageNr, levelSet) => {
                        let max = this.getMaxProgress(levelSet);
                        if (max <= this.robboGame.stageNr) {
                            window.localStorage.setItem("robbo:"+this.robboGame.level.currentLevelSet.name+":maxLevel", (this.robboGame.stageNr+1)+"");
                        }
                    };
                        // self.robboGame.startNewGame();
                    // self.robboGame.loadLevelSet(byt, w, h, 1);
                    // this.robboGame.startGameFromStage(2);
                    if (testLevelData) {
                        this.prepareTestLevel(testLevelData);
                    }
                    else {
                        this.robboGame.startDemo();
                    }
                    this.start();
                });
            });
        });
    }

    prepareTestLevel(data:number[]) {
        this.menuLayer.visible = false;
        this.levelsPickerLayer.visible = false;
        this.robboGame.loadLevelData(data);
    }

    getMaxProgress(levelSet?) {
        levelSet = levelSet || this.robboGame.level.currentLevelSet;
        let max = window.localStorage.getItem("robbo:"+levelSet.name+":maxLevel");
        if (max === undefined) return 0;
        return +max;
    }

    loadLevels(levels:String[], clbck:Function) {
        let levelsCount = levels.length;
        for (let i=0; i<levels.length; i++) {
            let url = this.rootFolder+levels[i]+".json";
            $.getJSON(url, {}, jsFile => {
                robbo.levels[i] = jsFile;
                levelsCount--
                if (!levelsCount) {
                    clbck();
                }
            });
        }
    }

    showLoading() {
        var label =  this.createMenuLabel("Loading...", this.width/2, 100, "white");
        label.draw(this.context);
    }

    createMenuLabel(text, x, y, color) {
        var label =  new Label(text, x, y, color);
        label.font = "bold 30px Lucida Console";
        label.bevelWidth = 2;
        label.bevelColor = "black";
        label.bevel = true;
        label.textAlign = "center";
        return label;
    }

    loadImageAssets(imagesSrc, callback:Function) {

        // if image array not provided then set default one
        if (!imagesSrc)
            imagesSrc = this.imgAssets;

        var numImages = 0,
            loadedImages = 0,
            images = {};

        for(var src in imagesSrc) {
            numImages++;
        }

        for(var src in imagesSrc) {
            images[src] = new Image();
            images[src].onload = () => {
                if(++loadedImages >= numImages) {
                    callback(images);
                }
            };
            images[src].src = this.rootFolder+imagesSrc[src];
            log("img loaded "+images[src].src);
        }
    }

    run() {
        this.robboGame.run();
    }

    draw() {
        if (this._beforeDrawCallback) this._beforeDrawCallback(this);

        var ctx = this.context;
        ctx.textBaseline = "top";

        ctx.clearRect(0, 0, this.width, this.height);

        ctx.fillStyle="black";
        ctx.fillRect(0, 0, this.width, 64);
        ctx.drawImage(this.imgAssets.title, 0, 0);
        ctx.drawImage(this.imgAssets.niepokoj, this.width-this.imgAssets.niepokoj.width, 0);

        ctx.translate(0, 64);
        this.robboGame.draw(ctx);
        ctx.translate(0, -64);
        this.stage.draw(ctx);

        if (!this.robboGame.isPreventDefault) {
            ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
            ctx.fillRect(0, 0, this.width, this.height);
            this.lostFocusLabel.draw(ctx);
        }

        if (this._afterDrawCallback) this._afterDrawCallback(this);
    }

    setFPS(fps:number) {
        this.fps = fps;
        this._timePerFrame = Math.floor(1000/fps);
    }

    start() {
        if (this.fps == 0) {
            log("Set fps before calling start!");
            return;
        }
        log('setting timeout');
        var self = this;
        this.gameIsReady = true;
        this.isGameRuning = true;
        setTimeout(function(){self.mainLoop();}, 1);
    }

    mainLoop() {

        if (this.imgAssetsReady) {
            this._mainLoopCounter++;
            var date = this.date;
            var startTime = date.getMilliseconds();
            this.run();
            this.draw();
            var timeTaken = date.getMilliseconds() - startTime;
            var timerOut = this._timePerFrame - timeTaken;
            timerOut = timerOut > 0 ? timerOut : 1;
            var fps = Math.floor( 1000 / (timeTaken < this._timePerFrame ? this._timePerFrame : timeTaken));
            var timeBetweenTicks = startTime - this._timeAfterMainLoop;
            this._debugFpsLabel.setText(this._mainLoopCounter+" fps:"+fps+" taken:"+timeTaken+" time from last frame:"+timeBetweenTicks+" timerOut:"+timerOut);
            this._timeAfterMainLoop = date.getMilliseconds();
        }
        var self = this;
        if (this.isGameRuning) {
            setTimeout(function () { self.mainLoop(); }, timerOut);
        }
    }

    setupMenu() {
        for (var i=0;i<robbo.levels.length;i++) {
            var lvl = robbo.levels[i];
            var label =  this.createMenuLabel(lvl.name, this.width/2, 100+i*30, "white");
            label.levelSet = lvl;
            label.height = 30;
            // label.setWidth(this.context);
            label.width = 400;
            this.menuLayer.add(label);
        }
    }

    attachEvents() {
        $(this.containerEl).mousemove(e=>this.checkMouseOver(e));
        $(this.containerEl).click(e=>this.checkMouseClick(e));
    }

    dettachEvents() {
        $(this.containerEl).off("mousemove click");
    }

    checkMouseOver(e:MouseEvent){

        if (!this.robboGame.isPreventDefault) return;

        if (this.menuLayer.visible) {
            this.menuLayer.uiObjects.forEach(ui=>ui.color = "white");
            let lastLabel = this.menuLayer.findCollision(e.offsetX, e.offsetY);
            if(lastLabel){
                lastLabel.color = "red";
            }
        }

        if (this.levelsPickerLayer.visible) {
            this.levelsPickerLayer.uiObjects.forEach(ui=>{
                // store color
                if (ui.disabled) return;
                ui._color = ui._color || ui.color;
                ui.color = ui._color;
            });
            let stageObj = this.levelsPickerLayer.findCollision(e.offsetX, e.offsetY);
            if (stageObj) {
                stageObj.color = "red";
            }
        }
    }

    checkMouseClick(e:MouseEvent) {

        if (!$(this.containerEl).is(e.target) && $(this.containerEl).has(e.target).length === 0) {
            return;
        }

        // check if was level picker
        if (this.levelsPickerLayer.visible) {
            let stageObj = this.levelsPickerLayer.findCollision(e.offsetX, e.offsetY);
            if (stageObj) {
                if (stageObj.levelSet == 'back') {
                    this.showMenu();
                    this.levelsPickerLayer.visible = false;
                }
                else {
                    // this.robboGame.level.setLevelSet(this.lastLabel.text);
                    // this.robboGame.level._setLevelSet(this.lastLabel.levelSet);
                    // this.robboGame.startNewGame();
                    this.robboGame.startGameFromStage(stageObj.levelSet-1);
                    this.levelsPickerLayer.visible = false;
                }
            }
            return;
        }

        if (this.menuLayer.visible) {
            let levelSetLabel = this.menuLayer.findCollision(e.offsetX, e.offsetY);
            if (levelSetLabel) {
                this.hideMenu();
                this.levelsPickerLayer.visible = true;
                let max = this.getMaxProgress(levelSetLabel.levelSet)+1;
                log('levelSetLabel', levelSetLabel.levelSet.name, levelSetLabel.levelSet);
                this.generateStagesMatrix(levelSetLabel.levelSet.count, max);
                this.robboGame.level._setLevelSet(levelSetLabel.levelSet);
            }
        }
    }

    generateStagesMatrix(count, max) {
        log("generateStagesMatrix", count, max);
        this.levelsPickerLayer.empty();
        var label =  this.createMenuLabel("<", 60, 100, "green");
        label.height = 30;
        label.width = 40;
        label.levelSet = "back";
        this.levelsPickerLayer.add(label);
        let levelNr = 1;
        for (let y = 0; y < 10; y++) {
            for (let x = 0; x < 10; x++) {

                if (y == 0 && x == 0) continue;
                
                var label =  this.createMenuLabel(levelNr, 60+x*50, 100+y*50, "white");
                label.height = 30;
                label.width = 40;
                label.levelSet = levelNr;
                label.disabled = levelNr > max;
                this.levelsPickerLayer.add(label);
    
                if (levelNr >= count) break;
                levelNr++;
            }
            if (levelNr >= count) break;
        }
        
    }

    showMenu() {
        this.menuLayer.visible = true;
    }

    hideMenu() {
        this.menuLayer.visible = false;
    }

    showLevels() {

    }

    stop() {
        this.isGameRuning = false;
        soundManager.destroy();
    }
}