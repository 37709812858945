// https://jsfiddle.net/aa0et7tr/220/ - JOYSTICK

import "./css/robbo.scss";
import { Game } from "./game/game";

declare global {
    interface Window { Game: any; }
}
window.Game = window.Game || Game;

/*
type defer = {
    promise?: any,
    resolve?: any,
    reject?: any
}
export function q_defer():defer {
    let result:defer = {};
    result.promise = new Promise((resolve, reject) => {
        result.resolve = resolve;
        result.reject = reject;
    })
    return result;
}

export var $q = {
    defer: q_defer,
    when: function(v) {return Promise.resolve(v);},
    resolve: function(v) {return Promise.resolve(v);},
    reject: function(v) {return Promise.reject(v);}
};
*/