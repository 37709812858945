import { GameObject } from "../game-object";

export class Stage extends GameObject {

    layers = [];
    name = "Stage";

    toString() {
        return "Stage: "+this.name;
    }

    constructor(name){
        super();
        this.name = name;
        this.layers = [];
    };

    addLayer(layer) {
        this.layers.push(layer);
    }

    draw(ctx) {
        var i, len = this.layers.length;
        for (i=0; i<len; i++) {
            this.layers[i].draw(ctx);
        }
    }
}
