import {RobboLevel} from "./robboLevel";
import { UIObject } from "./ui/uiobject";
import { Curtain } from "./curtain";
import { Hud } from "./hud";
import { ServRobbo } from "./robboServRobbo";
import { ServProjectile } from "./robboServProjectile";
import { ServCannon } from "./robboServCannon";
import { ServBird } from "./robboServBird";
import { ServEyes } from "./robboServEyes";
import { ServMagnez } from "./robboServMagnez";
import { ServSlidingBox } from "./robboServSlidingBox";
import { ServSuprise } from "./robboServSuprise";
import { ServBomb } from "./robboServBomb";
import { ServMirror } from "./robboServMirror";
import { ServMonsterLeft } from "./robboServMonsterLeft";
import { ServMonsterRight } from "./robboServMonsterRight";
import { soundManager } from "./sfx";
import { robbo, Game } from "./game";

export let Keyboard = {
    SPACE   : 32,
    LEFT    : 37,
    RIGHT   : 39,
    UP      : 38,
    DOWN    : 40,
    ESCAPE  : 27,
    ENTER   : 13
};

export function randRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
declare var $:any;

export class RobboGame extends UIObject {

    name = "RobboGame";

    game = null;
    viewWidth = 0;
    viewHeight = 0;

    GAME_START          = 1; // ROZPOCZĘCIE GRY
    GAME_START_LEVEL	= 2; // ROZPOCZĘCIE ETAPU
    GAME_RUN			= 3; // GRA W TRAKCIE
    GAME_PASS_LEVEL 	= 4; // ZAKOŃCZENIE ETAPU
    GAME_END			= 5; // KONIEC GRY - SKOŃCZYŁY SIĘ ZYCIA
    GAME_KILLED 		= 6; // ROBBO ZABITY, RESTART PLANSZY
    GAME_CONTINUE 		= 7; // KONTYNUACJA GRY
    GAME_NOP 			= 0; // GRA W STANIE JAŁOWYM, NIC SIE NIE DZIEJE
    inGameState         = 0;

    level:RobboLevel   = null;
    block   = null;
    hud     = null;
    curtain = null;

    lives           = 0;
    stageNr         = 0;
    score           = 0;
    keys            = 0;
    bullets         = 0;
    shipElements    = 0;
    shipReady       = false;
    isDemoMode        = false;
    demoLevelTimeCounter = 0;
    demoLevelDirection = false;
    demoLevelMovePause = 0;

    isLevelPassed   = false;
    robboKilled     = false;
    // robbo position used by "moving eyes"
    robboX          = 0;
    robboY          = 0;
    robboStopped    = false;	// robbo can't move (because demo or teleport or something else :)
    trueFalse       = false;	// tick-tock - used to make pause between game ticks
    tileRefreshPause= 0; 	    // pause count between changing tile set
    caveBlowCounter = 0;
    moveCannonPause = 0;

    /* game elements */
    servProjectile  = null;
    servMirror      = null;
    servSlidingBox  = null;
    servMagnez      = null;
    servBomb        = null;
    servMonsterRight= null;
    servMonsterLeft = null;
    servEyes        = null;
    servSuprise     = null;
    servBird        = null;
    servCannon      = null;
    servRobbo       = null;

    /* keyboard */
    isKey       = new Array(128);
    keyLeft     = false;
    keyRight    = false;
    keyUp       = false;
    keyDown     = false;
    keyFire     = false;
    isKeyFire   = false;

    quitGameCallback:Function    = null;
    levelPassedCallback:Function = null;

    isPreventDefault = false;

    debug = false;

    constructor(/*Game*/game:Game, viewWidth:number, viewHeight:number) {
        super(0, 0);
        this.game = game;
        this.level = new RobboLevel(game, viewWidth, viewHeight-32);    // 32=hud
        robbo.level = this.level;
        this.block = robbo.block;
        this.bindKeysEvents();
        this.curtain = new Curtain(this, viewWidth, viewHeight);
        this.hud = new Hud(this, viewWidth, viewHeight, game.imgAssets);
        this.debug = game.debug;
    }

    bindKeysEvents() {
        // document.addEventListener("keydown", e => {
        //     var key = e.keyCode || e.charCode;
        //     this.isKey[key] = true;
        // });
        // document.addEventListener("keyup", e => {
        //     var key = e.keyCode || e.charCode;
        //     this.isKey[key] = false;
        // });
        $(document).keydown(e => {
            if (this.isPreventDefault) {
                var key = e.keyCode || e.charCode;
                this.isKey[key] = true;
                e.preventDefault();
            }
        });
        $(document).keyup(e => {
            var key = e.keyCode || e.charCode;
            this.isKey[key] = false;
        });
    }

    draw(context) {
        
        switch(this.inGameState) {
            case this.GAME_RUN:
            case this.GAME_KILLED:
            case this.GAME_PASS_LEVEL:
                this.drawLevel(context);
                this.tileRefreshPause--;
                if(this.tileRefreshPause > 0) break;
                this.tileRefreshPause = 5;
                this.block.refreshTileSet();
                break;
        }
        this.curtain.draw(context);
        this.hud.draw(context);
    }

    drawLevel(context) {
//        context.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
        this.level._moveLevel();
        this.level._paintLevel(context);
    }

    setQuitGameCallback(fun:Function) {
        this.quitGameCallback = fun;
    }

    startNewGame() {
        this.isDemoMode = false;
        this.setGameState(this.GAME_START);
    }

    startGameFromStage(stageNr) {
        this.isDemoMode = false;
        this.setGameState(this.GAME_CONTINUE);
        this.stageNr = stageNr;
    }

    startDemo() {
        this.isDemoMode = true;
//        this.level.setLevelSet(new EmbededLevelsClass(), 16, 31, 50);
        this.setGameState(this.GAME_CONTINUE);
        this.stageNr = randRange(0, this.level.currentLevelsCount);
        console.log('start demo');
        //			demoLevelMovePause = 0;
        //			demoLevelDirection = false;
        this.demoLevelTimeCounter = 0;
        this.demoLevelMovePause = 1;
    }

    /**
     * elements have to be instantiated after level is loaded and prepared
     * because optimisation of access to level data array
     **/
    initElements() {
        this.servEyes = new ServEyes(this);
        this.servMagnez = new ServMagnez(this);
        this.servSlidingBox = new ServSlidingBox(this);
        this.servSuprise = new ServSuprise(this);
        this.servBomb = new ServBomb(this);
        this.servProjectile = new ServProjectile(this);
        this.servMirror = new ServMirror(this);
        this.servMonsterLeft = new ServMonsterLeft(this);
        this.servMonsterRight = new ServMonsterRight(this);
        this.servBird = new ServBird(this, this.servProjectile);
        this.servCannon = new ServCannon(this, this.servProjectile);
        this.servRobbo = new ServRobbo(this, this.servProjectile, this.servMirror);
    }

    setGameState(newState) {

        /* setting new state */
        switch(newState) {

            /* start game from very begining, loading levels etc */
            case this.GAME_START:
                this.stageNr = 0;
                this.lives = 8;
                this.score = 0;
                break;

            case this.GAME_RUN:
                break;

            /* state called when game end - all lives lost */
            case this.GAME_END:
                break;

            /* continue game from given level*/
            case this.GAME_CONTINUE:
                this.setGameState(this.GAME_START);
                newState = this.GAME_START;
                break;

            case this.GAME_START_LEVEL:
                if (!this.isDemoMode)
                    soundManager.play('start-level');
                this.robboKilled = false;
                this.isLevelPassed = false;
                this.caveBlowCounter = 0;
                if (this.testLevelData) {
                    this.level.prepareLevelData(this.testLevelData, 16, 31);
                }
                else {
                    this.level.loadLevelNr(this.stageNr);
                }
//					level.prepareLevelData(testLevelData, 16, 31);
//					level.setBuildInLevel(stageNr);
                this.block.setRandomWall();
                this.initElements();
                if (this.isDemoMode)
                    this.level.setScrollSpeed(6);
                else
                    this.level.setScrollSpeed(12);
                this.shipElements = this.level.getScrewCount();
                this.keys = 0;
                this.bullets = 0;
                this.shipReady = false;
                this.curtain.hide();
                this.robboStopped = false;
                break;

            case this.GAME_PASS_LEVEL:
                this.curtain.show();
                if (this.levelPassedCallback != null)
                    this.levelPassedCallback(this.stageNr, this.level.currentLevelSet);
                break;

            case this.GAME_KILLED:
                this.curtain.show();
                break;
        }
        this.inGameState = newState;
    }

    testLevelData:number[];

    loadLevelData(data:number[]) {
        this.isDemoMode = false;
        this.testLevelData = data;
        // this.level.prepareLevelData(data, 16, 31);
        this.setGameState(this.GAME_CONTINUE);
        this.stageNr = 0;
    }

    _doDemoMode() {
        this.demoLevelMovePause--;
        if (this.demoLevelMovePause > 0) return;
        this.demoLevelMovePause = 120;

        this.demoLevelTimeCounter++;
        if (this.demoLevelTimeCounter == 2) {
            this.demoLevelTimeCounter = 0;
            this.killRobbo();
        }

        if (this.demoLevelDirection) {
            this.level.setViewCenter(0, 0);
        }
        else {
            this.level.setViewCenter(0, 30);
        }
        this.demoLevelDirection = !this.demoLevelDirection;
    }

    run() {
        this.trueFalse = !this.trueFalse;
        this.moveCannonPause++;
        this.moveCannonPause %= 4;

        switch(this.inGameState) {

            case this.GAME_RUN:

                // stop game if user play and lost focus
                if (!this.isDemoMode && !this.isPreventDefault) {
                    return;
                }

                /* setting keys if not demo */
                if (!this.isDemoMode) {
                    this.setKeys(false);
                }
                else {
                    this._doDemoMode();
                }

                // do move only every second tick
                if (this.trueFalse) break;

                // check if we can activate ship
                if (this.shipElements == 0 && !this.shipReady) {
                    this.activateShip();
                }
                this.moveCreatures();
                this.moveRobbo();
                this.hud.setInfo(this.score, this.keys, this.stageNr, this.shipElements, this.bullets, this.lives);
                break;

            case this.GAME_END:
                if (this.quitGameCallback != null) {
                    this.setGameState(this.GAME_NOP);
                    this.quitGameCallback();
                }
                else {
                    this.startNewGame();
                }
                break;

            case this.GAME_START:
                this.setGameState(this.GAME_START_LEVEL);
                break;

            case this.GAME_START_LEVEL:
                this.setGameState(this.GAME_RUN);
                break;

            case this.GAME_PASS_LEVEL:
                // checking if curtain finished
                if(this.curtain.mode != 0) break;

                this.stageNr++;
                // this.storeCurrentProgress();
                if(this.stageNr >= this.level.currentLevelsCount) {
                    this.stageNr = 0;
                }
                this.setGameState(this.GAME_START_LEVEL);
                break;

            case this.GAME_KILLED:
                // checking if curtain finished
                if(this.curtain.mode != 0) break;

                if (this.isDemoMode) {
                    this.setGameState(this.GAME_CONTINUE);
                    this.stageNr = randRange(0, this.level.currentLevelsCount);
                    return;
                }

                this.lives--;
                if(this.lives == -1)
                    this.setGameState(this.GAME_END);
                else
                    this.setGameState(this.GAME_START_LEVEL);
                break;
        }
    }

    moveRobbo() {

        /* setting keys */
        this.servRobbo.keyLeft 	= this.keyLeft;
        this.servRobbo.keyRight = this.keyRight;
        this.servRobbo.keyDown 	= this.keyDown;
        this.servRobbo.keyUp 	= this.keyUp;
        this.servRobbo.keyFire 	= this.keyFire;

        for(var by = 1; by < this.level.levelHeight-1; by++) {
            for(var bx = 1; bx < this.level.levelWidth-1; bx++) {

                var block = this.level.currentLevelData[bx + by * this.level.levelWidth];

                // SKIP IF EMPTY OR WALL
                if(block == 0 || block == robbo.block.CHAR_WALL) continue;
                var bitMask = robbo.level.getBit(bx, by);

                // ****************************  ROBBO  ********************************
                if ( (bitMask & this.block.B_ROBBO) == this.block.B_ROBBO) {

                    this.robboX = bx;
                    this.robboY = by;

                    if (!this.isDemoMode)
                        this.level.setViewCenter(bx, by);

                    if (this.robboKilled) {
                        this.level.setBlock(this.block.CHAR_BOOM_1, bx, by);
                        return;
                    }
                    else {
                        if(!this.robboStopped) {
                            this.servRobbo.serv(bx, by);
                            return; // end - no need to continue searching
                        }
                    }
                }
            }
        }
    }


    moveCreatures() {

        // checking if whole level should explode
        if(this.caveBlowCounter > 0) {
            this.caveBlowCounter++;
            if(this.caveBlowCounter == 10) {
                soundManager.play('bomb');
                this.level.explodeAll();
                return;
            }
            // only two reasons when level explode - pass or die
            if(this.caveBlowCounter == 20) {
                if(this.robboKilled) 		// is robbo killed
                    this.setGameState(this.GAME_KILLED);
                else //if(isLevelPassed)	// pass level
                    this.setGameState(this.GAME_PASS_LEVEL);
                return;
            }
        }

        var Block = this.block,
            level = this.level;

        for(var by = 1; by < level.levelHeight-1; by++) {
            for(var bx = 1; bx < level.levelWidth-1; bx++) {

                var block = level.currentLevelData[bx + by * level.levelWidth];

                // JEŻELI JEST PUSTO LUB ŚCIANA TO POMIJAM ZNAK
                if(block == 0 || block == Block.CHAR_WALL) continue;

                // CZY TO ZNAK DO ZAMIANY
                if (block > Block.STOP_BIT) {
                    level.currentLevelData[bx + by * level.levelWidth] -= Block.STOP_BIT;
                    continue;
                }

                switch (block) {

                    // changing teleport into robbo
                    case Block.CHAR_TELE_BOOM1:
                        level.setBlock(Block.CHAR_TELE_BOOM2, bx, by);
                        continue;
                    case Block.CHAR_TELE_BOOM2:
                        level.setBlock(Block.CHAR_TELE_BOOM3, bx, by);
                        continue;
                    case Block.CHAR_TELE_BOOM3:
                        level.setBlock(Block.CHAR_TELE_BOOM4, bx, by);
                        continue;
                    case Block.CHAR_TELE_BOOM4:
                        level.setBlock(Block.CHAR_ROBBO_DOWN_A, bx, by);
                        this.stopRobbo(false);
                        continue;

                    // changing explode
                    case Block.CHAR_BOOM_1:
                        level.setBlock(Block.CHAR_BOOM_2, bx, by);
                        continue;
                    case Block.CHAR_BOOM_2:
                        level.setBlock(Block.CHAR_BOOM_3, bx, by);
                        continue;
                    case Block.CHAR_BOOM_3:
                        level.setBlock(Block.CHAR_BOOM_4, bx, by);
                        continue;
                    case Block.CHAR_BOOM_4:
                        level.setBlock(Block.CHAR_BOOM_5, bx, by);
                        continue;
                    case Block.CHAR_BOOM_5:
                        level.setBlock(Block.CHAR_BOOM_6, bx, by);
                        continue;
                    case Block.CHAR_BOOM_6:
                        level.setBlock(0, bx, by);
                        continue;

                    // surprise
                    case Block.CHAR_SURPRISE_BOOM1:
                        level.setBlock(Block.CHAR_SURPRISE_BOOM2, bx, by);
                        continue;
                    case Block.CHAR_SURPRISE_BOOM2:
                        level.setBlock(Block.CHAR_SURPRISE_BOOM3, bx, by);
                        continue;
                    case Block.CHAR_SURPRISE_BOOM3:
                        this.servSuprise.serv(bx, by);
                        continue;

                    case Block.CHAR_BOMBA2:
                        soundManager.play('bomb');
                        if(this.servBomb.serv(bx, by)) {
                            this.killRobbo();
                        }
                        continue;

                    case Block.CHAR_MAGNEZ_LEFT:
                    case Block.CHAR_MAGNEZ_RIGHT:
                        this.servMagnez.serv(bx, by);
                        continue;

                    case Block.CHAR_MOVING_EYES_A:
                        if (this.servEyes.serv(bx, by, this.robboX, this.robboY)) {
                            this.killRobbo();
                        }
                        continue;
                }

                var bitMask = level.getBit(bx, by);

                /* ****************************  PROJECTILE  ******************************* */
                if ( (bitMask & Block.B_PROJECTILE) == Block.B_PROJECTILE) {
                    if(this.servProjectile.serv(bx, by)) {
                        this.killRobbo();
                    }
                    continue;
                }
                /* ****************************  CANNON ****************************** */
                if ( (bitMask & Block.B_CANNON) == Block.B_CANNON) {
                    if (this.servCannon.serv(bx, by, this.moveCannonPause == 0)) {
                        this.killRobbo();
                    }
                    continue;
                }
                // ****************************  BIRD  *********************************
                if ( (bitMask & Block.B_BIRD) == Block.B_BIRD) {
                    this.servBird.serv(bx, by);
                    continue;
                }
                // **********************  ENEMY LEFTSIDE  *************************
                if ( (bitMask & Block.B_MON_LEFT) == Block.B_MON_LEFT) {
                    this.servMonsterLeft.serv(bx, by);
                    continue;
                }
                // **********************  ENEMY RIGHTSIDE *************************
                if ( (bitMask & Block.B_MON_RIGHT) == Block.B_MON_RIGHT) {
                    this.servMonsterRight.serv(bx, by);
                    continue;
                }
                /* *********************** SLIDING BOX  ************************** */
                if ( (bitMask & Block.B_BOX_SLIDE) == Block.B_BOX_SLIDE) {
                    this.servSlidingBox.serv(bx, by);
                    continue;
                }
            } // end for bx
        } // end for by
    }


    cantRobboFire  =false;	// flag if robbo make a shoot (used to control robbo shoting so he can't perform more shot during same key press event)
    setKeys(keepKeyStatus:boolean) {

        this.keyFire = this.isKey[Keyboard.SPACE]   || (keepKeyStatus ? this.keyFire : false);
        this.keyLeft = this.isKey[Keyboard.LEFT] 	|| (keepKeyStatus ? this.keyLeft : false);
        this.keyRight = this.isKey[Keyboard.RIGHT]  || (keepKeyStatus ? this.keyRight: false);
        this.keyUp = this.isKey[Keyboard.UP] 		|| (keepKeyStatus ? this.keyUp 	: false);
        this.keyDown = this.isKey[Keyboard.DOWN] 	|| (keepKeyStatus ? this.keyDown : false);

        /* if fire is not pressed or direction keys then robbo can fire again */
        if (!(this.keyLeft || this.keyRight || this.keyUp || this.keyDown) || !this.keyFire) {
            this.cantRobboFire = false;
        }
        /* if is after fire then also cant move */
        if (this.cantRobboFire)
            this.keyUp = this.keyDown = this.keyLeft = this.keyRight = false;

        if(this.isKey[Keyboard.ESCAPE] && !this.robboKilled) {
            this.killRobbo();
        }

        if(this.isKey[Keyboard.ENTER]) {
            // this.levelPassed();
        }
    }

    activateShip() {
        this.level.activateShip();
        this.shipReady = true;
    }

    stopRobbo(stop:boolean) {
        this.robboStopped = stop;
    }

    killRobbo() {
        this.robboKilled = true;
        soundManager.play('fireend2');
        this.caveBlowCounter = 1;
    }

    levelPassed() {
        this.isLevelPassed = true;
        this.caveBlowCounter = 1;
    }

    addScore(p:number) {
        this.score += p;
    }

    addLive(){
        this.lives++;
    }

    getBullets() {
        return this.bullets;
    }

    addBullets(c:number) {
        this.bullets += c;
    }

    robboShot(){
        this.cantRobboFire = true;
        this.bullets--;
    }

    getShipElements(){
        return this.shipElements;
    }

    decShipElements() {
        this.shipElements--;
    }

    incKeys() {
        this.keys++;
    }

    decKeys(){
        this.keys--;
    }

    getKeys() {
        return this.keys;
    }
}