import { UIObject } from "./uiobject";

export class Overlay extends UIObject {

    width = 0;
    height = 20; 
    color = "black";

    constructor(color, width, height){
        super(0,0);
        this.width = width;
        this.height = height;
        this.color = color;
        this.canBeClick = false;
    }

    draw(context:CanvasRenderingContext2D) {

        // context.fillStyle = this.color;
        context.clearRect(0, 0, this.width, this.height);
        // context.fillRect(this.x, this.y, this.width, this.height);
    }
}