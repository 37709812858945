import { GameElement } from "./robboGameElement";
import { randRange } from "./robboGame";

export class ServEyes extends GameElement {

    name = "Eyes";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(bx, by, robboX, robboY) {

        var killed = this.checkLRTB(bx, by);

        // CZY ROBIMY KROK W PRAWO
        if(this.getRandom() && this.getBit(bx+1, by) == 0)
        {
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx+1+by*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A+this.STOP_BIT;
            return killed;
        }

        // CZY ROBIMY KROK W LEWO
        if(this.getRandom() && this.getBit(bx-1, by) == 0)
        {
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx-1+by*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A;
            return killed;
        }

        // CZY ROBIMY KROK W GÓRĘ
        if(this.getRandom() && this.getBit(bx, by-1) == 0)
        {
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx+(by-1)*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A;
            return killed;
        }

        // CZY ROBIMY KROK W DÓŁ
        if(this.getRandom() && this.getBit(bx, by+1) == 0)
        {
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx+(by+1)*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A+this.STOP_BIT;
            return killed;
        }

        // ROBBO JEST NIŻEJ
        if(by < robboY)
        {
            // OCZY SCHODZĄ W DÓŁ
            if(this.getBit(bx, by+1) == 0)
            {
                this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
                this.caveChar[bx+(by+1)*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A+this.STOP_BIT;
                return killed;
            }
        }

        // ROBBO JEST WYŻEJ
        if(by > robboY)
        {
            // OCZY IDĄ DO GÓRY
            if(this.getBit(bx, by-1) == 0)
            {
                this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
                this.caveChar[bx+(by-1)*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A;
                return killed;
            }
        }

        // ROBBO JEST PO PRAWEJ
        if(bx < robboX)
        {
            // OCZY IDĄ W PRAWO
            if(this.getBit(bx+1, by) == 0)
            {
                this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
                this.caveChar[bx+1+by*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A+this.STOP_BIT;
                return killed;
            }
        }

        // ROBBO JEST PO LEWEJ
        if(bx > robboX)
        {
            // OCZY IDĄ W LEWO
            if(this.getBit(bx-1, by) == 0)
            {
                this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
                this.caveChar[bx-1+by*this.CAVE_WIDTH] = this.block.CHAR_MOVING_EYES_A;
                return killed;
            }
        }
        return killed;
    }

    getRandom() {
        return (randRange(0,10000) & 7) == 1;
    }

}

