import { GameElement } from "./robboGameElement";

export class ServMagnez extends GameElement {

    name = "Magnez";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(bx, by) {

        // setting defaults for magnes pulling left
        var dx = 1,
            rBlock = this.block.CHAR_MAG_ROBBO_R;
        if (this.caveChar[bx + by * this.CAVE_WIDTH] == this.block.CHAR_MAGNEZ_LEFT) {
            // default settings for magnes pulling right
            dx = -1;
            rBlock = this.block.CHAR_MAG_ROBBO_L;
        }

        // looking for a robbo in a line
        var x = bx;
        while(x != 0 && x != this.CAVE_WIDTH) {

            x += dx;
            var c = this.caveChar[x + by * this.CAVE_WIDTH] & 127;
            var mask = this.getBit(x, by);
            if (mask == 0) continue;

            // if there is something beside robbo we finish checking
            if((mask & this.block.B_ROBBO) != this.block.B_ROBBO) return;
            if((mask & this.block.B_SMTHG) == this.block.B_SMTHG) return;

            // get robbo, changing for "pulled robbo" + highest bit if set
            this.caveChar[x+by*this.CAVE_WIDTH] = rBlock+(this.isHighestBitSet(x, by) ? this.STOP_BIT : 0);
            return;
        }
    }
}

