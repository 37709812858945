import { GameObject } from "./game-object";

export class Hud extends GameObject {

    name = "HUD";

    robboCore   = null;
    assets      = null;
    height      = 0;

    constructor(/*Class.RobboGame*/robboCore, width, height, assets) {
        super();
        this.robboCore = robboCore;
        this.assets = assets;
//        this.CURTAIN_WIDTH = width;
//        this.CURTAIN_HEIGHT = height;
        this.height = height;
        this.bufferedCanvas = document.createElement("canvas");
        this.bufferedCanvas.width = width;
        this.bufferedCanvas.height = assets.hud.height;
        this.bufferedCanvasCtx = this.bufferedCanvas.getContext("2d");
    }

    bufferedCanvas = null;
    bufferedCanvasCtx = null;

    draw(context) {

        var y = this.height-this.bufferedCanvas.height; //32*12;
        context.drawImage(this.bufferedCanvas,
            0, 0, this.bufferedCanvas.width, this.bufferedCanvas.height,
            0, y, this.bufferedCanvas.width, this.bufferedCanvas.height);
    }

    setInfo(score, keys, stageNr, elements, bullets, lives) {

        this.bufferedCanvasCtx.fillStyle = "black"; //"rgba(0, 0, 0, 0.5)";
        this.bufferedCanvasCtx.fillRect(0, 0, this.bufferedCanvas.width, this.bufferedCanvas.height);
        this.bufferedCanvasCtx.drawImage(this.assets.hud,
            0, 0, this.assets.hud.width, this.assets.hud.height,
            0, 0, this.assets.hud.width, this.assets.hud.height);
        this._drawValue(0, 0, score, 6);
        this._drawValue(160, 0, elements, 2);
        this._drawValue(256, 0, lives, 2);
        this._drawValue(352, 0, keys, 2);
        this._drawValue(448, 0, bullets, 2);
        this._drawValue(544, 0, stageNr+1, 2);
    }

    _drawValue(x, y, val, round) {

        var digStr = Math.abs(val)+"";
        while(digStr.length < round) {
            digStr = "0"+digStr;
        }

        var drawFlag = true;
        for(var i = 0; i < digStr.length; i++) {

            var nr = digStr.charCodeAt(i)-"0".charCodeAt(0);
        //				if (nr != 0 || i == digStr.length-1) drawFlag = true;
        //				if (!drawFlag) continue;
//            var srcRect = new Rectangle(nr * 16, 0, 16, 32);
//            var destPoint = new Point(i*16+x, 0);



            this.bufferedCanvasCtx.drawImage(this.assets.digits,
                nr * 16, 0, 16, 32,
                i*16+x, 0, 16, 32);




//            hudBitmapData.copyPixels(digitsBitmap.bitmapData, srcRect, destPoint);
        }
    }



}


