import { GameElement } from "./robboGameElement";
import { randRange } from "./robboGame";

export class ServBird extends GameElement {

    name = "Bird";

    /*ServProjectile*/servStrzal = null;

    constructor(/*Class.RobboGame*/robboCore, /*ServProjectile*/servStrzal){
        super(robboCore);
        this.servStrzal = servStrzal;
    }

    serv(bx, by) {

        var caveChar = this.caveChar,
            CAVE_WIDTH = this.CAVE_WIDTH,
            Block = this.block,
            STOP_BIT = this.STOP_BIT;


        ///////////////////// PTAK PORUSZA SIĘ W LEWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_BIRD_LEFT_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx - 1, by) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx - 1, by, Block.CHAR_BIRD_LEFT_A);
                //        bx--;
            }
            // W PRZECIWNYM WYPADKU ZAWRÓĆ
            else
                this.setPtak(bx, by, Block.CHAR_BIRD_RIGHT_A+STOP_BIT);
            return;
        }

        ///////////////////// PTAK PORUSZA SIĘ W PRAWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_BIRD_RIGHT_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx + 1, by) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx + 1, by, (Block.CHAR_BIRD_RIGHT_A+STOP_BIT));
                //        bx++;
            }
            else
                this.setPtak(bx, by, Block.CHAR_BIRD_LEFT_A+STOP_BIT);
            return;
        }

        ///////////////////// PTAK PORUSZA SIĘ W DÓŁ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_BIRD_DOWN_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx, by+1) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx, by+1, (Block.CHAR_BIRD_DOWN_A+STOP_BIT));
                //        by++;
            }
            // W PRZECIWNYM WYPADKU ZAWRÓĆ
            else
                this.setPtak(bx, by, Block.CHAR_BIRD_UP_A+STOP_BIT);
            return;
        }

        ///////////////////// PTAK PORUSZA SIĘ W GÓRĘ
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_BIRD_UP_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx, by-1) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx, by-1, Block.CHAR_BIRD_UP_A);
                //        by--;
            }
            // W PRZECIWNYM WYPADKU ZAWRÓĆ
            else
                this.setPtak(bx, by, Block.CHAR_BIRD_DOWN_A+STOP_BIT);
            return;
        }



        ///////////////////// STRZELAJĄCY PTAK PORUSZA SIĘ W LEWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_FIRE_BIRD_LEFT_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx - 1, by) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx - 1, by, Block.CHAR_FIRE_BIRD_LEFT_A);
                // ptak sie przesunal wiec jezeli strzeli to tez musi byc przesuniete
                bx--;
            }
            // W PRZECIWNYM WYPADKU ZAWRÓĆ
            else
                this.setPtak(bx, by, Block.CHAR_FIRE_BIRD_RIGHT_A+STOP_BIT);

            if(this.random1())
                this.servStrzal.startShoot(bx, by+1, Block.CHAR_PROJECTILE_DOWN_A);
            return;
        }

        ///////////////////// STRZELAJĄCY PTAK PORUSZA SIĘ W PRAWO
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_FIRE_BIRD_RIGHT_A) {
            // JEŻELI PUSTO TO LEĆ DALEJ
            if (this.getBit(bx + 1, by) == 0) {
                caveChar[bx + by * CAVE_WIDTH] = 0;
                this.setPtak(bx + 1, by, (Block.CHAR_FIRE_BIRD_RIGHT_A+STOP_BIT));
                bx++;
            }
            else
                this.setPtak(bx, by, Block.CHAR_FIRE_BIRD_LEFT_A+STOP_BIT);

            if(this.random1())
                this.servStrzal.startShoot(bx, by+1, Block.CHAR_PROJECTILE_DOWN_A);
        }
        return;
    }

    setPtak(x, y, c1) {
        this.caveChar[x + y * this.CAVE_WIDTH] = c1;
    }

    random1() {
        return randRange(0,10) == 1; // ? true:false;
    }
}

