import { GameObject } from "../game-object";
import { Label } from "./label";

export class Layer extends GameObject {

    name = "layer";
    uiObjects = null;
    visible = true;

    constructor(name) {
        super();
        this.name = name;
        this.uiObjects = [];
        this.visible = true;
        console.log('creating layer '+name);
    }

    toString() {
        return `Layer: ${this.name} objects count: ${this.uiObjects.length}`;
    }

    add(uiobject) {
        // console.log(`adding to layer ${this.name} ${uiobject.text} `+this.uiObjects.length);
        if (this.uiObjects.indexOf(uiobject) != -1) {
            console.log('Object already in layer');
            return;
        }
        this.uiObjects.push(uiobject);
    }

    draw(context) {
        if (!this.visible) return;
        var i, len = this.uiObjects.length;
        for (i=0; i<len; i++) {
            this.uiObjects[i].draw(context);
        }
    }

    empty() {
        this.uiObjects.length = 0;
    }

    findCollision(_x, _y) {
        let result:Label;
        for (let i=0; i<this.uiObjects.length; i++) {
            if (this.uiObjects[i] instanceof Label) {
                let uiObject:Label = this.uiObjects[i];
                if (uiObject.disabled) continue;
                let x = _x;
                let y = _y;
                if (uiObject.textAlign == "center") {
                    x += uiObject.width/2;
                }
                if (x < uiObject.x || x > uiObject.x + uiObject.width) continue;
                if (y < uiObject.y || y > uiObject.y + uiObject.height) continue;
                
                // matching
                result = uiObject;
                break;
            }
        }
        return result;
    }
}
