import { GameObject } from "../game-object";

export class UIObject extends GameObject {

    name = "UIObject";
    x = 0;
    y = 0;
    canBeClick = true;

    constructor(x,y) {
        super();
        this.x = x;
        this.y = y;
        // console.log('konstruktor UIObject');
    }

    toString() {
        return this.name +
            " x:"+this.x+
            " y:"+this.y;
    }

    draw(context) {
        console.log(`drawing ${this.name} (you need to overwrite this method)`);
    }
}