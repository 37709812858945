import { GameElement } from "./robboGameElement";

export class ServSlidingBox extends GameElement {

    name = "SlidingBox";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(bx, by) {

        var caveChar = this.caveChar,
            Block = this.block,
            CAVE_WIDTH = this.CAVE_WIDTH,
            STOP_BIT = this.STOP_BIT;

        // W PRAWO //////////////////
        if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_SLIDE_BOX_RIGHT)
        {

            // CZY TO NIESPODZIANKA
            if(caveChar[bx+1+by*CAVE_WIDTH] == Block.CHAR_SURPRISE) 
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                caveChar[bx+1 + by * CAVE_WIDTH] = Block.CHAR_SURPRISE_BOOM1+STOP_BIT;
                return;
            }

            // CZY TO BOMBA
            if(caveChar[bx+1+by*CAVE_WIDTH] == Block.CHAR_BOMBA)
            {
                caveChar[bx+1 + by * CAVE_WIDTH] = Block.CHAR_BOMBA2+STOP_BIT;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                return;
            }

            // CZY ZNAK NIE DO ZABICIA
            if((this.getBit(bx+1, by) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY JEST COKOLWIEK
            if(this.getBit(bx+1, by) > 0)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;;
                caveChar[bx+1 + by * CAVE_WIDTH] = Block.CHAR_BOOM_1+STOP_BIT;
                return;
            }

            // PRZESUŃ KRATKĘ
            caveChar[bx + by * CAVE_WIDTH] = 0;
            caveChar[bx+1 + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX_RIGHT+STOP_BIT;
            return;
        }

        // W LEWO //////////////////
        if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_SLIDE_BOX_LEFT)
        {
            // CZY TO NIESPODZIANKA
            if(caveChar[bx-1+by*CAVE_WIDTH] == Block.CHAR_SURPRISE) 
            {
                caveChar[bx-1 + by * CAVE_WIDTH] = Block.CHAR_SURPRISE_BOOM1+STOP_BIT;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY TO BOMBA
            if(caveChar[bx-1+by*CAVE_WIDTH] == Block.CHAR_BOMBA)
            {
                caveChar[bx-1 + by * CAVE_WIDTH] = Block.CHAR_BOMBA2;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                return;
            }

            // CZY ZNAK NIE DO ZABICIA
            if((this.getBit(bx-1, by) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY JEST COKOLWIEK
            if(this.getBit(bx-1, by) > 0)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                caveChar[bx-1 + by * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                return;
            }

            // PRZESUŃ KRATKĘ
            caveChar[bx + by * CAVE_WIDTH] = 0;
            caveChar[bx-1 + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX_LEFT;
            return;
        }

        // W GÓRĘ //////////////////
        if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_SLIDE_BOX_UP)
        {
            // CZY TO NIESPODZIANKA
            if(caveChar[bx+(by-1)*CAVE_WIDTH] == Block.CHAR_SURPRISE) 
            {
                caveChar[bx+(by-1)*CAVE_WIDTH] = Block.CHAR_SURPRISE_BOOM1+STOP_BIT;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY TO BOMBA
            if(caveChar[bx+(by-1)*CAVE_WIDTH] == Block.CHAR_BOMBA)
            {
                caveChar[bx + (by-1) * CAVE_WIDTH] = Block.CHAR_BOMBA2;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                return;
            }

            // CZY ZNAK NIE DO ZABICIA
            if((this.getBit(bx, by-1) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY JEST COKOLWIEK
            if(this.getBit(bx, by-1) > 0)
            {
                caveChar[bx + (by-1) * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // PRZESUŃ KRATKĘ
            caveChar[bx + by * CAVE_WIDTH] = 0;
            caveChar[bx + (by-1) * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX_UP;
            return;
        }

        // W DÓŁ //////////////////
        if (caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_SLIDE_BOX_DOWN)
        {

            // CZY TO NIESPODZIANKA
            if(caveChar[bx+(by+1)*CAVE_WIDTH] == Block.CHAR_SURPRISE) 
            {
                caveChar[bx+(by+1)*CAVE_WIDTH] = Block.CHAR_SURPRISE_BOOM1+STOP_BIT;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY TO BOMBA
            if(caveChar[bx+(by+1)*CAVE_WIDTH] == Block.CHAR_BOMBA)
            {
                caveChar[bx + (by+1) * CAVE_WIDTH] = Block.CHAR_BOMBA2+STOP_BIT;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                return;
            }

            // CZY ZNAK NIE DO ZABICIA
            if((this.getBit(bx, by+1) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            {
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // CZY JEST COKOLWIEK
            if(this.getBit(bx, by+1) > 0)
            {
                caveChar[bx + (by+1) * CAVE_WIDTH] = Block.CHAR_BOOM_1;
                caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX;
                return;
            }

            // PRZESUŃ KRATKĘ
            caveChar[bx + by * CAVE_WIDTH] = 0;
            caveChar[bx + (by+1) * CAVE_WIDTH] = Block.CHAR_SLIDE_BOX_DOWN+STOP_BIT;
            return;
        }
        return;
    }
}

