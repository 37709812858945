import { GameObject } from "./game-object";
import { Game, robbo } from "./game";

declare var $: any;

export class RobboLevel extends GameObject {

    name = "Robbo Level";

    currentLevelData = [
        3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,
        3, 0, 0, 0, 0, 3, 0, 0, 0, 3, 4, 3, 0, 0, 0, 3,
        3, 0, 0, 0, 0, 3, 0, 44, 0, 3, 0, 3, 0, 44, 0, 3,
        3, 0, 0, 0,89, 0, 0, 0, 0, 3, 0, 3, 0, 0, 0, 3,
        3, 0, 0, 0, 6, 3, 0, 2, 0, 0, 0, 3, 0, 3, 0, 3,
        3, 3, 4, 3, 3, 3, 0, 3, 3, 3, 3, 3, 0, 3, 0, 3,
        3, 0, 0, 0, 0, 3, 0, 0, 3, 0, 0, 14, 0, 3, 0, 3,
        3, 0, 0, 0, 2, 3, 4, 2, 3, 0, 0, 14, 0, 3, 0, 3,
        3, 2, 3, 3, 3, 3, 3, 0, 3, 0, 0, 14, 0, 3, 0, 3,
        3, 0, 0, 0, 2, 3, 0, 0, 3, 0, 3, 3, 3, 3, 0, 3,
        3, 0, 0, 0, 0, 3, 4, 0, 3, 0, 0, 0, 3, 38, 0, 3,
        3, 3, 3, 3, 0, 3, 3, 3, 3, 3, 3, 0, 3, 0, 0, 3,
        3, 0, 0, 0, 0, 3, 0, 0, 0, 0, 13, 0, 3, 0, 0, 3,
        3, 0, 20, 0, 0, 3, 0, 2, 0, 0, 3, 3, 3, 0, 0, 3,
        3, 0, 0, 0, 0, 3, 3, 0, 3, 3, 3, 6, 0, 0, 0, 3,
        3, 0, 0, 0, 0, 3, 0, 0, 0, 0, 3, 3, 3, 0, 3, 3,
        3, 2, 0, 20, 2, 3, 0, 0, 0, 2, 0, 3, 2, 0, 2, 3,
        3, 2, 0, 2, 2, 0, 0, 2, 2, 0, 0, 3, 0, 0, 0, 3,
        3, 2, 2, 2, 0, 0, 0, 0, 2, 2, 3, 3, 0, 0, 0, 3,
        3, 12, 2, 0, 2, 2, 0, 2, 4, 0, 3, 0, 0, 0, 0, 3,
        3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 2, 3, 3, 0, 3,
        3, 0, 0, 0, 3, 0, 0, 0, 0, 0, 3, 0, 12, 3, 13, 3,
        36, 0, 0, 0, 0, 0, 4, 0, 0, 0, 3, 0, 3, 3, 0, 3,
        3, 0, 0, 0, 3, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 3,
        3, 0, 27, 0, 3, 2, 3, 3, 3, 3, 3, 0, 3, 0, 3, 3,
        3, 0, 0, 0, 0, 0, 3, 4, 0, 0, 0, 2, 0, 0, 0, 3,
        3, 3, 3, 3, 3, 0, 3, 3, 3, 3, 3, 0, 3, 0, 3, 3,
        3, 0, 0, 0, 3, 0, 3, 0, 0, 0, 3, 0, 3, 3, 3, 3,
        3, 0, 5, 0, 0, 0, 0, 0, 4, 0, 3, 0, 0, 0, 0, 3,
        3, 0, 0, 0, 3, 0, 3, 0, 0, 0, 3, 0, 0, 0, 4, 3,
        3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3
    ];

    tiles = [];

    game = null;

    bufferedCanvas = null;
    bufferedCanvasCtx = null;
    caveX = 0;
    caveY = 0;
    TILE_WIDTH = 32;
    TILE_HEIGHT = 32;
    TILES_PER_ROW = 8;  // image tiles per row
    X_VIEW_AREA = 17;
    Y_VIEW_AREA = 11;
    levelWidth = 16;
    levelHeight = 31;
    scrollSpeed = 12;
    destLevelX = 0;
    destLevelY = 0;

    currentLevelSet = void 0;
    currentLevelSetData = [];
    currentLevelsWidth = 0;
    currentLevelsHeight = 0;
    currentLevelsCount = 0;

    currentLvlNr = 0;

    useCSSView = false;

    constructor(game:Game, viewWidth:number, viewHeight:number){
        super();
        this.game = game;
        this.bufferedCanvas = document.createElement("canvas");
        this.bufferedCanvas.width = viewWidth;
        this.bufferedCanvas.height = viewHeight;
        this.bufferedCanvasCtx = this.bufferedCanvas.getContext("2d"); //new Image(viewWidth, viewHeight);
        this.setLevelSet('Robbo levels'); //.data, 16, 31, 49);

        this.X_VIEW_AREA = Math.floor(viewWidth/this.TILE_WIDTH);
        this.Y_VIEW_AREA = Math.floor(viewHeight/this.TILE_HEIGHT)+1;
    }

    setLevelSet(levelSetName) { //array, w, h, max) {

        let i, levelSet;
        for (i in robbo.levels) {
            if (robbo.levels[i].name == levelSetName) break;
        }
        levelSet = robbo.levels[i];
        this._setLevelSet(levelSet);
    }
    
    _setLevelSet(levelSet) {
        this.currentLevelSet = levelSet;
        this.currentLevelSetData = levelSet.data; //this.currentLevelData; //_loadLevelsFromText(byteArray);
        this.currentLevelsWidth = levelSet.width;
        this.currentLevelsHeight = levelSet.height;
        this.currentLevelsCount = levelSet.count;

        if (this.useCSSView) {
            // fill tiles contaier with tiles
            var containerNode = $('#tiles-container');
            containerNode.empty();
            for (var y=0;y<this.currentLevelsHeight+2;y++) {
                for (var x=0;x<this.currentLevelsWidth+2;x++) {
                    containerNode.append('<div class="tile tile-'+x+'-'+y+'">'+x+'-'+y+'</div>');
                }
            }
            this.tiles = $('#tiles-container .tile');
        }
    }

    _paintLevel(context) {

        var ctx = this.bufferedCanvasCtx;
        ctx.clearRect(0, 0, this.bufferedCanvas.width, this.bufferedCanvas.height);

        var tileSet = this.game.imgAssets.tiles,
            caveX = this.caveX,
            caveY = this.caveY,
            TILE_WIDTH = this.TILE_WIDTH,
            TILE_HEIGHT = this.TILE_HEIGHT,
            TILES_PER_ROW = this.TILES_PER_ROW,
            Y_VIEW_AREA = this.Y_VIEW_AREA,
            X_VIEW_AREA = this.X_VIEW_AREA,
            levelWidth = this.levelWidth,
            levelHeight = this.levelHeight,
            cx = Math.floor(caveX/TILE_WIDTH),
            cy = Math.floor(caveY/TILE_HEIGHT),
            x, y;


        this.bufferedCanvasCtx.strokeStyle = "#ffffff"
        this.bufferedCanvasCtx.fillStyle = "black";
        this.bufferedCanvasCtx.font = "normal 14px Monaco";
        this.bufferedCanvasCtx.textBaseline = "top";

        for (y = cy; y < cy+Y_VIEW_AREA && y < levelHeight; y++) {
            for (x = cx; x < cx+X_VIEW_AREA && x < levelWidth; x++) {
                var blockNr = this.currentLevelData[x + y * levelWidth] & 127;
                if (blockNr == 0) continue;

                var tn = robbo.block.currentBlocksImgIndex[blockNr];
                var tnx = tn % TILES_PER_ROW;
                var tny = Math.floor(tn / TILES_PER_ROW);
                var ox = (x-cx)*TILE_WIDTH;
                var oy = (y-cy)*TILE_HEIGHT;
                this.bufferedCanvasCtx.drawImage(tileSet,
                    tnx*TILE_WIDTH, tny*TILE_HEIGHT, TILE_WIDTH, TILE_HEIGHT,
                    ox-caveX%TILE_WIDTH, oy-caveY%TILE_HEIGHT, TILE_WIDTH, TILE_HEIGHT);

                if (this.game.debug) {
                    this.bufferedCanvasCtx.strokeText(blockNr, ox - caveX % TILE_WIDTH, oy - caveY % TILE_HEIGHT);
                    this.bufferedCanvasCtx.fillText(blockNr, ox - caveX % TILE_WIDTH, oy - caveY % TILE_HEIGHT);
                }

            }
        }
        context.drawImage(this.bufferedCanvas, 0, 0);

        if (this.useCSSView) {
            this.setViewTiles();
        }
    }

    _moveLevel() {

        var caveX = this.caveX,
            caveY = this.caveY,
            destLevelX = this.destLevelX,
            destLevelY = this.destLevelY,
            scrollSpeed = this.scrollSpeed;

        if (caveY < destLevelY) {
            caveY += scrollSpeed;
            if (caveY > destLevelY)
                caveY = destLevelY;
        }
        if (caveY > destLevelY) {
            caveY -= scrollSpeed;
            if (caveY < destLevelY)
                caveY = destLevelY;
            if (caveY < 0)
                caveY = 0;
        }

        if (caveX < destLevelX) {
            caveX += scrollSpeed;
            if (caveX > destLevelX)
                caveX = destLevelX;
        }
        if (caveX > destLevelX) {
            caveX -= scrollSpeed;
            if (caveX < destLevelX)
                caveX = destLevelX;
            if (caveX < 0)
                caveX = 0;
        }

        this.caveX = caveX;
        this.caveY = caveY;
    }

    setScrollSpeed(v) {
        this.scrollSpeed = v;
    }

    Y_VIEW_MARGIN = 3;
    X_VIEW_MARGIN = 3;

    setViewCenter(rx, ry) {

        // checking if y is inside view frame
        var frameTop = Math.floor(this.destLevelY/this.TILE_HEIGHT) + this.Y_VIEW_MARGIN;
        var frameBottom = Math.floor( frameTop + (this.Y_VIEW_AREA - this.Y_VIEW_MARGIN) / 2 );

        // check top
        if (ry < frameTop) {
            this.destLevelY = ry - (this.Y_VIEW_MARGIN + 1);
            if (this.destLevelY < 0)
                this.destLevelY = 0;
            this.destLevelY *= this.TILE_HEIGHT;
        }
        // check bottom
        if (ry > frameBottom) {
            this.destLevelY = ry + (this.Y_VIEW_MARGIN + 3) - this.Y_VIEW_AREA;
            if (this.destLevelY + this.Y_VIEW_AREA >= this.levelHeight)
                this.destLevelY = this.levelHeight - this.Y_VIEW_AREA;
            this.destLevelY *= this.TILE_HEIGHT;
        }

        // checking if x is inside view frame
        var frameLeft = Math.floor( this.destLevelX/this.TILE_WIDTH) + this.X_VIEW_MARGIN;
        var frameRight = Math.floor( frameLeft + (this.X_VIEW_AREA - (this.X_VIEW_MARGIN * 2)) / 2 ); //frameLeft + 10;

        // check left
        if (rx < frameLeft) {
            this.destLevelX = rx - (this.X_VIEW_MARGIN + 1);
            if (this.destLevelX < 0)
                this.destLevelX = 0;
            this.destLevelX *= this.TILE_WIDTH;
        }
        // check right
        if (rx > frameRight) {
            this.destLevelX = rx + (this.X_VIEW_MARGIN + 3) - this.X_VIEW_AREA;
            if (this.destLevelX + this.X_VIEW_AREA >= this.levelWidth)
                this.destLevelX = this.levelWidth - this.X_VIEW_AREA;
            this.destLevelX *= this.TILE_WIDTH;
        }
    }

    explodeAll() {
        for(let i=0, b=0; i < this.currentLevelData.length; i++) {
            b = this.currentLevelData[i];
            if(b != 0 && b != robbo.block.CHAR_WALL && b != robbo.block.CHAR_NIC)
                this.currentLevelData[i] = robbo.block.CHAR_BOOM_1;
        }
    }

    activateShip() {
        for(let i=0, b=0; i < this.currentLevelData.length; i++) {
            b = this.currentLevelData[i];
            if(b == robbo.block.CHAR_SHIP_A) {
                this.currentLevelData[i] = robbo.block.CHAR_SHIP_BLINK;
                return;
            }
        }
    }

    /**
     * checking level and return screw count
     * */
    getScrewCount() {
        var sr = 0;
        for (var y = 1; y < this.levelHeight-1; y++)
            for (var x = 1; x < this.levelWidth-1; x++)
            if(this.currentLevelData[x + y * this.levelWidth] == robbo.block.CHAR_SCREW)
            // checking if screw is accessible
                if (this.getBit(x - 1, y) != robbo.block.B_CANT_KBOM ||
                    this.getBit(x + 1, y) != robbo.block.B_CANT_KBOM ||
                    this.getBit(x, y + 1) != robbo.block.B_CANT_KBOM ||
                    this.getBit(x, y - 1) != robbo.block.B_CANT_KBOM) sr++;
            return sr;
    }

    /**
     * returning bit status of block on given position
     **/
    getBit(x, y) {
        return robbo.block.blocksMask[this.currentLevelData[x + y * this.levelWidth] & 127];
    }

    setBlock(block, x, y){
        return this.currentLevelData[x + y * this.levelWidth] = block;
    }

    loadLevelNr(lvlNr) {
        if (lvlNr >= this.currentLevelsCount)
            lvlNr %= this.currentLevelsCount;

        var lvlSize = this.currentLevelsWidth * this.currentLevelsHeight;
        var lvlOffset = lvlNr * lvlSize;
        this.currentLvlNr = lvlNr;
        console.log('loading lvlNr', lvlNr);
        this.prepareLevelData(this.currentLevelSetData.slice(lvlOffset, lvlOffset+lvlSize), this.currentLevelsWidth, this.currentLevelsHeight);
    }

    /**
     * copy level data and preparing them to play
     **/
    prepareLevelData(lvlData:number[], lvlWidth, lvlHeight) {

        this.currentLevelData = new Array((lvlWidth + 2) * (lvlHeight + 2));
        for (var i = 0; i < this.currentLevelData.length; i++) {
            this.currentLevelData[i] = robbo.block.CHAR_NIC;
        }

        for (var y = 0; y < lvlHeight; y++) {
            for (var x = 0; x < lvlWidth; x++) {

                var c = lvlData[ x + y * lvlWidth ];
                this.currentLevelData[ (x + 1) + (y + 1) * (lvlWidth+2) ] = c;
            }
        }
        this.levelWidth = lvlWidth+2;
        this.levelHeight = lvlHeight+2;
    }

    setViewTiles() {
        var tiles = this.tiles,
            tileNr, ox, oy, len = this.currentLevelData.length,
            TILE_WIDTH = this.TILE_WIDTH,
            TILE_HEIGHT = this.TILE_HEIGHT,
            TILES_PER_ROW = this.TILES_PER_ROW;

        for (var i=0;i<len;i++) {

            tileNr = this.currentLevelData[i] & 127;

            if (tileNr > 0) {
                tileNr = robbo.block.currentBlocksImgIndex[tileNr];
            }

            ox = (tileNr % TILES_PER_ROW)*TILE_WIDTH;
            oy = Math.floor(tileNr / TILES_PER_ROW)*TILE_HEIGHT;
            if (tiles[i].lastTile != tileNr) {
                tiles[i].style.backgroundPosition =  "-"+ox+"px -"+oy+"px";
                tiles[i].innerText = "";
                tiles[i].lastTile = tileNr;
            }
        }
    }

}