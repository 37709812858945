import { GameElement } from "./robboGameElement";

export class ServBomb extends GameElement {

    name = "Bomb";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(bx, by) {
        this.cave.setBlock(this.block.CHAR_BOOM_1, bx, by);
        var killed = false;
        killed = this.checkAndDrestroy(bx + 1, by, true);
        killed = this.checkAndDrestroy(bx - 1, by, false) ? true: killed;
        killed = this.checkAndDrestroy(bx, by - 1, false) ? true: killed;
        killed = this.checkAndDrestroy(bx + 1, by - 1, false) ? true: killed;
        killed = this.checkAndDrestroy(bx - 1, by - 1, false) ? true: killed;
        killed = this.checkAndDrestroy(bx, by + 1, true) ? true: killed;
        killed = this.checkAndDrestroy(bx + 1, by + 1, true) ? true: killed;
        killed = this.checkAndDrestroy(bx - 1, by + 1, true) ? true: killed;
        return killed;
    }

    checkAndDrestroy(bx, by, addSkipBit){

        var Block = this.block,
            caveChar = this.caveChar,
            CAVE_WIDTH = this.CAVE_WIDTH,
            STOP_BIT = this.STOP_BIT;

        // is that something undestructible
        if((this.getBit(bx, by) & this.block.B_CANT_BOOM) == Block.B_CANT_BOOM)
            return false;

        // is that robbo?
        if((this.getBit(bx, by) & Block.B_ROBBO) == Block.B_ROBBO) {
            caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1 + (addSkipBit ? STOP_BIT : 0);
            return true;
        }

        // is that next bomb?
        if(caveChar[bx + by * CAVE_WIDTH] == Block.CHAR_BOMBA) {
            caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOMBA2 + (addSkipBit ? STOP_BIT : 0);
            return false;
        }

        // destroy it
        caveChar[bx + by * CAVE_WIDTH] = Block.CHAR_BOOM_1 + (addSkipBit ? STOP_BIT : 0);
        return false;
    }
}

