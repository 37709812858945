import { UIObject } from "./uiobject";

export class Label extends UIObject {

    name = "Label";
    text = "";
    color = null; //0x00ffffff;
    disabledColor = "gray";
    bevelColor = null;
    bevelWidth = 1;
    bevel = false;
    font = "normal 12px Monaco";
    lineWidth = null;
    strokeStyle = null;
    textAlign = 'left';
    levelSet = void 0;   // assigned level set in menu
    disabled = false;

    width = 0;
    height = 20;

    constructor(text, x, y, color, font?){

        super(x,y);
        this.text = text;

        if (color !== undefined)
            this.color = color;
        if (font !== undefined)
            this.font = font;
    }

    setText(text) {
        this.text = text;
    }

    draw(context) {

        if (this.font)
            context.font = this.font;
        if (this.lineWidth != null)
            context.lineWidth = this.lineWidth;
        if (this.strokeStyle != null)
            context.strokeStyle = this.strokeStyle;

        context.textAlign = this.textAlign;

        // get text metrics
        if (!this.width) {
            let metrics = context.measureText(this.text);
            this.width = metrics.width;
        }

        context.fillStyle = "red";
//        context.fillRect(this.x-width/2, this.y, width, 30);

        context.textBaseline = 'top';
        if (this.bevel) {
            context.fillStyle = this.bevelColor;
            context.fillText( this.text, this.x-this.bevelWidth, this.y);
            context.fillText( this.text, this.x+this.bevelWidth, this.y);
            context.fillText( this.text, this.x, this.y+this.bevelWidth);
            context.fillText( this.text, this.x, this.y-this.bevelWidth);
        }

        if (this.color)
            context.fillStyle = this.color;
        if (this.disabled) 
            context.fillStyle = this.disabledColor;
        context.fillText( this.text, this.x, this.y);

        // context.fillRect(this.x, this.y, this.width, this.height);
    }

    setWidth(context) {

        if (this.font)
            context.font = this.font;
        if (this.lineWidth != null)
            context.lineWidth = this.lineWidth;
        if (this.strokeStyle != null)
            context.strokeStyle = this.strokeStyle;

        let metrics = context.measureText(this.text);
        this.width =  metrics.width;
    }
}