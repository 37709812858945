import { GameElement } from "./robboGameElement";

export class ServMirror extends GameElement {

    name ="Mirror";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    lx = 0;
    ly = 0;

    serv(bx, by, direction, lustro) {

        var lx = this.lx = bx;
        var ly = this.ly = by;

        // SZUKAM LUSTRA OD TEGO MIEJSCA A JEŻELI NIE MA TO OD POCZĄTKU
        if(!this.search(bx, by, lustro))
            this.search(0, 0, lustro);

        lx = this.lx;
        ly = this.ly;

        this.robboCore.stopRobbo(true);
        if(direction == this.block.CHAR_ROBBO_LEFT_A) {
            if(this.set1(lx-1, ly)) return;
            if(this.set1(lx, ly+1)) return;
            if(this.set1(lx, ly-1)) return;
            if(this.set1(lx+1, ly)) return;
            this.caveChar[bx+1+by*this.CAVE_WIDTH] = this.block.CHAR_TELE_BOOM1+this.STOP_BIT;
            return;
        }

        if(direction == this.block.CHAR_ROBBO_RIGHT_A) {
            if(this.set1(lx+1, ly)) return;
            if(this.set1(lx, ly-1)) return;
            if(this.set1(lx, ly+1)) return;
            if(this.set1(lx-1, ly)) return;
            this.caveChar[bx-1+by*this.CAVE_WIDTH] = this.block.CHAR_TELE_BOOM1+this.STOP_BIT;
            return;
        }

        if(direction == this.block.CHAR_ROBBO_UP_A) {
            if(this.set1(lx, ly-1)) return;
            if(this.set1(lx+1, ly)) return;
            if(this.set1(lx-1, ly)) return;
            if(this.set1(lx, ly+1)) return;
            this.caveChar[bx+(by+1)*this.CAVE_WIDTH] = this.block.CHAR_TELE_BOOM1+this.STOP_BIT;
            return;
        }

        if(direction == this.block.CHAR_ROBBO_DOWN_A)
        {
            if(this.set1(lx, ly+1)) return;
            if(this.set1(lx-1, ly)) return;
            if(this.set1(lx+1, ly)) return;
            if(this.set1(lx, ly-1)) return;
            this.caveChar[bx+(by-1)*this.CAVE_WIDTH] = this.block.CHAR_TELE_BOOM1+this.STOP_BIT;
            return;
        }
    }

    set1(lx, ly) {
        if(this.getBit(lx, ly) == 0) {
            this.caveChar[lx + ly * this.CAVE_WIDTH] = this.block.CHAR_TELE_BOOM1+this.STOP_BIT;
            return true;
        }
        return false;
    }

    // WYSZUKUJE LUSTRO Z KTÓREGO MOŻNA WYJŚĆ
    search(bx, by, lustro) {
        for (var i= bx+1+by*this.CAVE_WIDTH; i < this.caveChar.length; i++) {

            if (this.caveChar[i] == lustro) {

                this.ly = Math.floor(i / this.CAVE_WIDTH);
                this.lx = i % this.CAVE_WIDTH;

                if(this.getBitMaskByOffset(i-1) == 0) return true;
                if(this.getBitMaskByOffset(i-this.CAVE_WIDTH) == 0) return true;
                if(this.getBitMaskByOffset(i+1) == 0) return true;
                if(this.getBitMaskByOffset(i+this.CAVE_WIDTH) == 0) return true;
            }
        }
        return false;
    }

    getBitMaskByOffset(x) {
        return this.block.blocksMask[this.caveChar[x] & 127];
    }
}

