import { GameElement } from "./robboGameElement";
import { soundManager } from "./sfx";

export class ServRobbo extends GameElement {

    name = "Robbo";

    bx = 0;
    by = 0;

    keyLeft = false;
    keyRight= false;
    keyUp   = false;
    keyDown = false;
    keyFire = false;

    robbo_frame = false;
    char_robbo = 0;

    servStrzal = null;
    servLustro = null;

    constructor(/*Class.RobboGame*/robboCore, servStrzal, servLustro){
        super(robboCore);
        this.servLustro = servLustro;
        this.servStrzal = servStrzal;
    }

    serv(x, y) {

        var bx = this.bx = x,
            by = this.by = y;

        /* first check for enemies */
        if (this.checkBitMaskLRTB(bx, by, this.block.B_MON_LEFT) || this.checkBitMaskLRTB(bx, by, this.block.B_MON_RIGHT) || this.checkBitMaskLRTB(bx, by, this.block.B_BIRD)) {
            this.robboCore.killRobbo();
            return;
        }

        /* move robbo */
        this._serv(x, y);

        /* after possible move also check for enemies */
        if (this.checkBitMaskLRTB(bx, by, this.block.B_MON_LEFT) || this.checkBitMaskLRTB(bx, by, this.block.B_MON_RIGHT) || this.checkBitMaskLRTB(bx, by, this.block.B_BIRD)) {
            this.robboCore.killRobbo();
        }
    }

    _serv(x, y) {

        var bx = this.bx,
            by = this.by;

        // CZY PRZYCIĄGA GO MAGNEZ W PRAWO
        if(this.caveChar[bx+by*this.CAVE_WIDTH] == this.block.CHAR_MAG_ROBBO_L)
        {
            if(this.getBit(bx+1, by) != 0)
            {
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_BOOM_1;
                this.robboCore.killRobbo();
                return;
            }
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx+1+by*this.CAVE_WIDTH] = this.block.CHAR_MAG_ROBBO_L+this.STOP_BIT;
            return;
        }

        // czy przyciąga go magnez w lewo
        if(this.caveChar[bx+by*this.CAVE_WIDTH] == this.block.CHAR_MAG_ROBBO_R) {

            if(this.getBit(bx-1, by) != 0) {
                this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_BOOM_1;
                this.robboCore.killRobbo();
                return;
            }
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            this.caveChar[bx-1+by*this.CAVE_WIDTH] = this.block.CHAR_MAG_ROBBO_R;
            return;
        }

        /* here just changing robbo graphics so it look like walking */
        if(this.keyLeft) {
            this.switchRobboLook(this.block.CHAR_ROBBO_LEFT_A, this.block.CHAR_ROBBO_LEFT_B);
        }
        else if(this.keyRight) {
            this.switchRobboLook(this.block.CHAR_ROBBO_RIGHT_A, this.block.CHAR_ROBBO_RIGHT_B);
        }
        else if(this.keyUp) {
            this.switchRobboLook(this.block.CHAR_ROBBO_UP_A, this.block.CHAR_ROBBO_UP_B);
        }
        else if(this.keyDown) {
            this.switchRobboLook(this.block.CHAR_ROBBO_DOWN_A, this.block.CHAR_ROBBO_DOWN_B);
        }


        /* is robbo shooting */
        if (this.keyFire && this.robboCore.getBullets() > 0) {

            var robboChar = this.caveChar[bx + by * this.CAVE_WIDTH]
            if (this.keyLeft) {
                this.shoot(bx - 1, by, this.block.CHAR_PROJECTILE_LEFT_A);
                return;
            }
            if (this.keyRight) {
                this.shoot(bx + 1, by, this.block.CHAR_PROJECTILE_RIGHT_A);
                return;
            }
            if (this.keyUp) {
                this.shoot(bx, by - 1, this.block.CHAR_PROJECTILE_UP_A);
                return;
            }
            if (this.keyDown) {
                this.shoot(bx, by + 1, this.block.CHAR_PROJECTILE_DOWN_A);
                return;
            }
        }

        /** HERE ROBBO IS MOVING */
        /* left */
        if(this.keyLeft) {
            this.checkAndMove(-1,0,this.block.CHAR_ROBBO_LEFT_A, false);
            return;
        }
        /* right */
        if(this.keyRight) {
            this.checkAndMove(+1,0,this.block.CHAR_ROBBO_RIGHT_A, true);
            return;
        }
        /* up */
        if(this.keyUp) {
            this.checkAndMove(0,-1,this.block.CHAR_ROBBO_UP_A, false);
            return;
        }
        /* down */
        if(this.keyDown) {
            this.checkAndMove(0,+1,this.block.CHAR_ROBBO_DOWN_A, true);
            return;
        }
    }


    switchRobboLook(c1, c2) {
        this.robbo_frame = this.robbo_frame ? false :  true;
        this.char_robbo = this.robbo_frame ? c1 : c2;
        this.cave.setBlock(this.char_robbo, this.bx, this.by);
        soundManager.play('step');
    }

    shoot(x, y, c) {
        this.robboCore.robboShot();
        this.servStrzal.startShoot(x, y, c, true);
    }

    /**
     * inspecting and moving robbo to given position
     * */
    checkAndMove(dX, dY, robboBlock, robboFrame) {

        var bx = this.bx,
            by = this.by,
            b = this.getBit(bx+dX, by+dY);

        // is empty place
        if(b == 0) {
            // empty, we can move
            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+this.STOP_BIT):this.char_robbo;
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            // setting position to check for enemies
            this.bx+=dX;
            this.by+=dY;
            return;
        }

        // is that mirror
        if ((this.getBit(bx+dX, by+dY) & this.block.B_MIRROR) == this.block.B_MIRROR) {

            this.caveChar[bx + by * this.CAVE_WIDTH] = this.block.CHAR_BOOM_3;
            this.servLustro.serv(bx+dX, by+dY, robboBlock, this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH]);
            soundManager.play('mirror');
            return;
        }

        // is that space ship
        if (this.caveChar[bx + dX + (by+dY) * this.CAVE_WIDTH] == this.block.CHAR_SHIP_BLINK) {

        //				// check if all elements collected
        //				if(robboCore.getShipElements() == 0) {
        //
            this.robboCore.levelPassed();
            this.caveChar[bx + by * this.CAVE_WIDTH] = 0;
            this.addScore(5000);
            return;
        //				}
            // we need to keep checking because maybe we can push it
        }

        // is that part of the ship - screw
        if(this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == this.block.CHAR_SCREW) {

            if(this.robboCore.getShipElements() > 0) {
                this.addScore(100);
                this.robboCore.decShipElements();
            }
            else {
                this.addScore(1000);
            }

            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+this.STOP_BIT):this.char_robbo;
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            soundManager.play('screw');
            return;
        }

        // is that additional life?
        if(this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == this.block.CHAR_ADD_LIVE) {

            this.addScore(500);
            this.robboCore.addLive();
            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+this.STOP_BIT):this.char_robbo;
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            soundManager.play('live');
            return;
        }

        // is that key
        if(this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == this.block.CHAR_KEY) {

            this.addScore(150);
            this.robboCore.incKeys();
            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+this.STOP_BIT):this.char_robbo;
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            soundManager.play('key');
            return;
        }

        // is that door
        if(this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == this.block.CHAR_DOOR) {

            if(this.robboCore.getKeys() > 0) this.robboCore.decKeys();
            else return;
            this.addScore(125);
            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = 0;
            this.caveChar[bx+by*this.CAVE_WIDTH] = this.char_robbo;
            soundManager.play('door');
            return;
        }

        // is that bullets
        if(this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == this.block.CHAR_BULLET) {

            this.addScore(250);
            this.robboCore.addBullets(9);
            this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+this.STOP_BIT):this.char_robbo;
            this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
            soundManager.play('bullets');
            return;
        }

        // is that something we can push
        if((b & this.block.B_CAN_PUSH) == this.block.B_CAN_PUSH) {

            var c = this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH];
            var stop = this.STOP_BIT;
            if(c == this.block.CHAR_SLIDE_BOX) {

                stop = 0;
                switch(robboBlock) {
                    case this.block.CHAR_ROBBO_DOWN_A:
                        c = this.block.CHAR_SLIDE_BOX_DOWN;
                        break;
                    case this.block.CHAR_ROBBO_UP_A:
                        c = this.block.CHAR_SLIDE_BOX_UP;
                        break;
                    case this.block.CHAR_ROBBO_LEFT_A:
                        c = this.block.CHAR_SLIDE_BOX_LEFT;
                        break;
                    case this.block.CHAR_ROBBO_RIGHT_A:
                        c = this.block.CHAR_SLIDE_BOX_RIGHT;
                        break;
                }
            }
            if(this.getBit(bx+dX+dX, by+dY+dY) == 0) {
                this.caveChar[bx+dX+dX+(by+dY+dY)*this.CAVE_WIDTH] = robboFrame?(c+stop):c;
                this.caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] = robboFrame?(this.char_robbo+stop):this.char_robbo;
                this.caveChar[bx+by*this.CAVE_WIDTH] = 0;
                soundManager.play('push');
            }
        }
    }

    addScore(points){
        this.robboCore.addScore(points);
    }
}

