import { GameObject } from "./game-object";

export class Curtain extends GameObject {

    name = "Curtain";

    robboCore = null;
    constructor(/*Class.RobboGame*/robboCore, width, height){
        super();
        this.robboCore = robboCore;
        this.CURTAIN_WIDTH = width;
        this.CURTAIN_HEIGHT = height;

        this.bufferedCanvas = document.createElement("canvas");
        this.bufferedCanvas.width = width;
        this.bufferedCanvas.height = height;
        this.bufferedCanvasCtx = this.bufferedCanvas.getContext("2d");
    };

    bufferedCanvasCtx = null;
    bufferedCanvas = null;
    curtainPos = 0;
    CURTAIN_WIDTH = 576;
    CURTAIN_HEIGHT = 450;
    leftX = 0;
    rightX = 0;
    fadeToVal = 0.0;
    mode = 0;
    alpha = 1;

    MODE_STOP = 0;
    MODE_SHOW = 1;
    MODE_HIDE = 2;
    MODE_FADEIN = 3;
    MODE_FADEOUT = 4;
    MODE_FADETO = 5;

    callbackFun = null;

    show(callback) {
        this.slideIn(callback);
//        this.fadeIn(undefined, callback);
    }

    hide(callback) {
        this.slideOut(callback);
//        this.fadeOut(undefined, callback);
    }

    slideIn(callback) {
        this.leftX = this.CURTAIN_WIDTH;
        this.rightX = this.CURTAIN_WIDTH;
        this.mode = this.MODE_SHOW;
        this.callbackFun = callback;
    }

    slideOut(callback) {
        this.leftX = 0;
        this.rightX = this.CURTAIN_WIDTH;
        this.mode = this.MODE_HIDE;
        this.callbackFun = callback;
    }

    // curatin appear
    fadeIn(to, callback) {
        if (to === undefined) {
            to = 1.0;
        }
        this.leftX = 0;
        this.rightX = this.CURTAIN_WIDTH;
        this.mode = this.MODE_FADEIN;
        this.fadeToVal = to;
        this.callbackFun = callback;
    }

    // curtain disappear
    fadeOut(to, callback) {
        if (to === undefined) {
            to = 0.0;
        }
        this.leftX = 0;
        this.rightX = this.CURTAIN_WIDTH;
        this.mode = this.MODE_FADEOUT;
        this.fadeToVal = to;
        this.callbackFun = callback;
    }

    fadeTo(to, callback){
        this.leftX = 0;
        this.rightX = this.CURTAIN_WIDTH;
        this.mode = this.MODE_FADETO;
        this.fadeToVal = to;
        this.callbackFun = callback;
    }

    drawCurtain(context) {

        this.bufferedCanvasCtx = context;
        this.bufferedCanvasCtx.fillStyle = "rgba(0, 0, 0, "+this.alpha+")";
//        this.bufferedCanvasCtx.clearRect(0, 0, this.bufferedCanvas.width, this.bufferedCanvas.height);
        this.bufferedCanvasCtx.fillRect(this.leftX, 0, this.rightX-this.leftX, this.CURTAIN_HEIGHT);
//        this.x = this.leftX;
//        this.graphics.beginFill(0x0);
//        this.graphics.drawRect(this.leftX, 0, this.rightX-this.leftX, this.CURTAIN_HEIGHT);
//        this.graphics.endFill();
    }

    draw(context) {

//        if (this.mode === this.MODE_STOP) {
//            return;
//        }

        if (this.mode == this.MODE_SHOW) {
            this.leftX -= 48;
            if (this.leftX <= 0) {
                this.mode = this.MODE_STOP;
                if (this.callbackFun != null) this.callbackFun();
            }
        }

        else
            if (this.mode == this.MODE_HIDE) {
                this.rightX -= 48;
                if (this.rightX <= 0) {
                    this.mode = this.MODE_STOP;
                    if (this.callbackFun != null) this.callbackFun();
                }
            }

            else
            if (this.mode == this.MODE_FADEOUT) {
                this.alpha -= 0.08;
                if (this.alpha <= this.fadeToVal) {
                    this.alpha = this.fadeToVal;
                    this.mode = this.MODE_STOP;
                    if (this.callbackFun != null) this.callbackFun();
                }
            }

            else
            if (this.mode == this.MODE_FADEIN) {
                this.alpha += 0.08;
                if (this.alpha >= this.fadeToVal) {
                    this.alpha = this.fadeToVal;
                    this.mode = this.MODE_STOP;
                    if (this.callbackFun != null) this.callbackFun();
                }
            }
            else
            if (this.mode == this.MODE_FADETO) {
                if (this.alpha < this.fadeToVal) {
                    this.alpha += 0.08;
                    if (this.alpha >= this.fadeToVal) {
                        this.alpha = this.fadeToVal;
                        this.mode = this.MODE_STOP;
                        if (this.callbackFun != null) this.callbackFun();
                    }
                }
                else
                if (this.alpha > this.fadeToVal) {
                    this.alpha -= 0.08;
                    if (this.alpha <= this.fadeToVal) {
                        this.alpha = this.fadeToVal;
                        this.mode = this.MODE_STOP;
                        if (this.callbackFun != null) this.callbackFun();
                    }
                }

            }

            this.drawCurtain(context);
        }
}


