import { GameElement } from "./robboGameElement";

export class ServMonsterRight extends GameElement {

    name = "Monster Right";

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(bx, by) {

        var caveChar = this.caveChar,
            CAVE_WIDTH = this.CAVE_WIDTH,
            STOP_BIT = this.STOP_BIT,
            Block = this.block;

        // TRZYMA SIĘ GÓRY I IDZIE W PRAWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_MR_RIGHT_A)
        {
            // CZY MA IŚĆ DO GÓRY
            // SPRAWDZAM CZY MA COŚ NAD GŁOWĄ
            if(this.getBit(bx, by-1) == 0)
            {
                // IDZIE DO GÓRY
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by-1)*CAVE_WIDTH] = Block.CHAR_MR_UP_A;
                return;
            }

            // CZY MOŻE PRZESUNĄĆ SIĘ W PRAWO
            // SPRAWDZAM CZY MA COŚ PO PRAWEJ STRONIE
            if(this.getBit(bx+1, by) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+1+by*CAVE_WIDTH] = Block.CHAR_MR_RIGHT_A+STOP_BIT;
                return;
            }

            // IDZIE W DÓŁ
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_MR_DOWN_A;
            return;
        }

        // TRZYMA SIĘ LEWEJ I IDZIE W GÓRĘ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_MR_UP_A)
        {
            // CZY MA IŚĆ W LEWO
            // SPRAWDZAM CZY MA COŚ PO LEWEJ
            if(this.getBit(bx-1, by) == 0)
            {
                // IDZIE W LEWO
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx-1+by*CAVE_WIDTH] = Block.CHAR_MR_LEFT_A;
                return;
            }

            // CZY MOŻE PRZESUNĄĆ SIĘ W GÓRĘ
            // SPRAWDZAM CZY MA COŚ NAD SOBĄ
            if(this.getBit(bx, by-1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by-1)*CAVE_WIDTH] = Block.CHAR_MR_UP_A;
                return;
            }

            // IDZIE W PRAWO
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_MR_RIGHT_A;
            return;
        }

        // TRZYMA SIĘ DOŁU I IDZIE W LEWO
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_MR_LEFT_A)
        {
            // CZY MA IŚĆ W DÓŁ
            // SPRAWDZAM CZY MA COŚ NA DOLE
            if(this.getBit(bx, by+1) == 0)
            {
                // IDZIE W LEWO
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by+1)*CAVE_WIDTH] = Block.CHAR_MR_DOWN_A+STOP_BIT;
                return;
            }

            // CZY MOŻE PRZESUNĄĆ SIĘ W LEWO
            // SPRAWDZAM CZY MA COŚ PO LEWEJ
            if(this.getBit(bx-1, by) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx-1+by*CAVE_WIDTH] = Block.CHAR_MR_LEFT_A;
                return;
            }

            // IDZIE W GÓRĘ
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_MR_UP_A;
            return;
        }

        // TRZYMA SIĘ PRAWEJ I IDZIE W DÓŁ
        if(caveChar[bx+by*CAVE_WIDTH] == Block.CHAR_MR_DOWN_A)
        {
            // CZY MA IŚĆ W PRAWO
            // SPRAWDZAM CZY MA COŚ NA PRAWEJ
            if(this.getBit(bx+1, by) == 0)
            {
                // IDZIE W PRAWO
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+1+by*CAVE_WIDTH] = Block.CHAR_MR_RIGHT_A+STOP_BIT;
                return;
            }

            // CZY MOŻE PRZESUNĄĆ SIĘ W DÓŁ
            // SPRAWDZAM CZY MA COŚ NA DOLE
            if(this.getBit(bx, by+1) == 0)
            {
                caveChar[bx+by*CAVE_WIDTH] = 0;
                caveChar[bx+(by+1)*CAVE_WIDTH] = Block.CHAR_MR_DOWN_A+STOP_BIT;
                return;
            }

            // IDZIE W LEWO
            caveChar[bx+by*CAVE_WIDTH] = Block.CHAR_MR_LEFT_A;
        }
    }
}

