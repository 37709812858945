import { GameElement } from "./robboGameElement";
import { soundManager } from "./sfx";

export class ServProjectile extends GameElement {

    name ="Projectile";

    bx = 0;
    by = 0;

    constructor(/*Class.RobboGame*/robboCore){
        super(robboCore);
    }

    serv(x, y) {
        this.bx = x;
        this.by = y;
        if(this._serv(x, y)) {
            this.robboCore.killRobbo();
        }
    }

    _serv(bx, by) {

        var caveChar = this.caveChar,
            Block = this.block;

        // W PRAWO //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_PROJECTILE_RIGHT_A) {
            return this.set2(1,0,true, Block.CHAR_PROJECTILE_RIGHT_A);
        }

        // W LEWO //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_PROJECTILE_LEFT_A) {
            return this.set2(-1,0,false, Block.CHAR_PROJECTILE_LEFT_A);
        }

        // W DÓŁ //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_PROJECTILE_DOWN_A) {
            return this.set2(0,1,true, Block.CHAR_PROJECTILE_DOWN_A);
        }

        // W GÓRĘ //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_PROJECTILE_UP_A) {
            return this.set2(0,-1,false, Block.CHAR_PROJECTILE_UP_A);
        }

        //////////////////////////////POCISK CIĄGŁY
        // W PRAWO //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_RIGHT_A)
        {
            // CZY TO ŚRODEK STRZAŁU
            if(caveChar[bx+1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_RIGHT_A ||
                caveChar[bx+1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_RIGHT_B ||
                caveChar[bx+1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_RIGHT_B+this.STOP_BIT)
                return false;
            return this.setCiagly(1,0, true, Block.CHAR_LONG_PROJECTILE_RIGHT_A, Block.CHAR_LONG_PROJECTILE_RIGHT_B);
        }

        // W PRAWO (WRACAJĄCY) //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_RIGHT_B)
        {
            // CZY TO KONIEC WRACANIA
            if (caveChar[bx - 1 + by * this.CAVE_WIDTH] != Block.CHAR_LONG_PROJECTILE_RIGHT_A)
            {
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_5;
                return false;
            }
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx-1 + by * this.CAVE_WIDTH] = Block.CHAR_LONG_PROJECTILE_RIGHT_B;
            return false;
        }


        // W LEWO //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_LEFT_A)
        {
            // CZY TO ŚRODEK STRZAŁU
            if(caveChar[bx-1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_LEFT_A ||
                caveChar[bx-1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_LEFT_B ||
                caveChar[bx-1+by*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_LEFT_B+this.STOP_BIT)
                return false;

            return this.setCiagly(-1,0, false, Block.CHAR_LONG_PROJECTILE_LEFT_A, Block.CHAR_LONG_PROJECTILE_LEFT_B);

        }

        // W LEWO (WRACAJĄCY) //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_LEFT_B)
        {
            // CZY TO KONIEC WRACANIA
            if (caveChar[bx + 1 + by * this.CAVE_WIDTH] != Block.CHAR_LONG_PROJECTILE_LEFT_A)
            {
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_5;
                return false;
            }
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx+1 + by * this.CAVE_WIDTH] = Block.CHAR_LONG_PROJECTILE_LEFT_B+this.STOP_BIT;
            return false;
        }


        // W DÓŁ //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_DOWN_A)
        {
            // CZY TO ŚRODEK STRZAŁU
            if(caveChar[bx+(by+1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_DOWN_A ||
                caveChar[bx+(by+1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_DOWN_B ||
                caveChar[bx+(by+1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_DOWN_B+this.STOP_BIT)
                return false;

            return this.setCiagly(0,1, true, Block.CHAR_LONG_PROJECTILE_DOWN_A, Block.CHAR_LONG_PROJECTILE_DOWN_B);
        }

        // W DÓŁ (WRACAJĄCY) //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_DOWN_B)
        {
            // CZY TO KONIEC WRACANIA
            if (caveChar[bx + (by-1) * this.CAVE_WIDTH] != Block.CHAR_LONG_PROJECTILE_DOWN_A)
            {
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_5;
                return false;
            }
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx + (by-1) * this.CAVE_WIDTH] = Block.CHAR_LONG_PROJECTILE_DOWN_B;
            return false;
        }


        // W GÓRĘ //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_UP_A)
        {
            // CZY TO ŚRODEK STRZAŁU
            if(caveChar[bx+(by-1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_UP_A ||
                caveChar[bx+(by-1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_UP_B ||
                caveChar[bx+(by-1)*this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_UP_B+this.STOP_BIT)
                return false;

            return this.setCiagly(0,-1, false, Block.CHAR_LONG_PROJECTILE_UP_A, Block.CHAR_LONG_PROJECTILE_UP_B);
        }

        // W GÓRĘ (WRACAJĄCY) //////////////////
        if (caveChar[bx + by * this.CAVE_WIDTH] == Block.CHAR_LONG_PROJECTILE_UP_B) {
            // CZY TO KONIEC WRACANIA
            if (caveChar[bx + (by+1) * this.CAVE_WIDTH] != Block.CHAR_LONG_PROJECTILE_UP_A) {
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_5;
                return false;
            }
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx + (by+1) * this.CAVE_WIDTH] = Block.CHAR_LONG_PROJECTILE_UP_B+this.STOP_BIT;
            return false;
        }

        ///////////////////////////////// BLASTER
        // W PRAWO
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BLAST_PROJECTILE_RIGHT) {
            return this.setBlaster(1,0, true, Block.CHAR_BLAST_PROJECTILE_RIGHT);
        }

        // W LEWO
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BLAST_PROJECTILE_LEFT) {
            return this.setBlaster(-1,0, false, Block.CHAR_BLAST_PROJECTILE_LEFT);
        }

        // W GÓRĘ
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BLAST_PROJECTILE_UP) {
            return this.setBlaster(0,-1, false, Block.CHAR_BLAST_PROJECTILE_UP);
        }

        // W DÓŁ
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BLAST_PROJECTILE_DOWN) {
            return this.setBlaster(0,+1, true, Block.CHAR_BLAST_PROJECTILE_DOWN);
        }
        return false;
    }

    setBlaster(liczX, liczY, bo, block) {
        var bx = this.bx,
            by = this.by;
        this.caveChar[bx+by*this.CAVE_WIDTH] = this.block.CHAR_BOOM_2;
        if((this.getBit(bx+liczX, by+liczY) & this.block.B_CANT_KILL) == this.block.B_CANT_KILL)
            return false;

        // CZY TO BOMBA
        if(this.caveChar[bx+liczX+(by+liczY)*this.CAVE_WIDTH] == this.block.CHAR_BOMBA)
        {
            this.caveChar[bx+liczX + (by+liczY) * this.CAVE_WIDTH] = bo?(this.block.CHAR_BOMBA2+this.STOP_BIT):this.block.CHAR_BOMBA2;
            return false;
        }

        // CZY TO ROBBO
        if((this.getBit(bx+liczX, by+liczY) & this.block.B_ROBBO) == this.block.B_ROBBO)
        {
            this.caveChar[bx+liczX+(by+liczY)*this.CAVE_WIDTH] = bo?(block+this.STOP_BIT):block;
            return true;
        }
        this.caveChar[bx+liczX+(by+liczY)*this.CAVE_WIDTH] = bo?(block+this.STOP_BIT):block;
        return false;
    }

    setCiagly(liczX, liczY, bo, block_A, block_B){
        var Block = this.block,
            caveChar = this.caveChar,
            bx = this.bx,
            by = this.by;

        // CZY TO NIESPODZIANKA
        if(caveChar[bx+liczX+(by+liczY)*this.CAVE_WIDTH] == Block.CHAR_SURPRISE)
        {
            caveChar[bx+by*this.CAVE_WIDTH] = block_B;
            caveChar[bx+liczX + (by+liczY) * this.CAVE_WIDTH] = bo?Block.CHAR_SURPRISE_BOOM1+this.STOP_BIT:Block.CHAR_SURPRISE_BOOM1;
            return false;
        }

        // CZY TO BOMBA
        if(caveChar[bx+liczX+(by+liczY)*this.CAVE_WIDTH] == Block.CHAR_BOMBA)
        {
            caveChar[bx+liczX + (by+liczY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOMBA2+this.STOP_BIT:Block.CHAR_BOMBA2;
            caveChar[bx + by * this.CAVE_WIDTH] = block_B;
            return false;
        }

        // CZY ZNAK NIE DO ZABICIA
        if((this.getBit(bx+liczX, by+liczY) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
        {
            //trace("wstawiam");
            //robboCore.gamePause =true;
            caveChar[bx + by * this.CAVE_WIDTH] = block_B; //bo?(znak_B+STOP_BIT):znak_B;
            return false;
        }

        // CZY TO ROBBO
        if((this.getBit(bx+liczX, by+liczY) & Block.B_ROBBO) == Block.B_ROBBO)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = block_B;
            caveChar[bx+liczX + (by+liczY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOOM_1+this.STOP_BIT:Block.CHAR_BOOM_1;
            return true;
        }

        // CZY JEST COKOLWIEK
        if(this.getBit(bx+liczX, by+liczY) > 0)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = block_B;
            caveChar[bx+liczX + (by+liczY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOOM_1+this.STOP_BIT:Block.CHAR_BOOM_1;
            return false;
        }

        // PRZESUŃ POCISK
        this.set1(bx+liczX, by+liczY, bo == true ? (block_A+this.STOP_BIT):block_A);
        return false;
    }

    set2(dX, dY, bo, block) {

        var Block = this.block,
            caveChar = this.caveChar,
            bx = this.bx,
            by = this.by;

        // CZY TO NIESPODZIANKA
        if(caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == Block.CHAR_SURPRISE)
        {
            caveChar[bx+by*this.CAVE_WIDTH] = 0;
            caveChar[bx+dX + (by+dY) * this.CAVE_WIDTH] = bo?Block.CHAR_SURPRISE_BOOM1+this.STOP_BIT:Block.CHAR_SURPRISE_BOOM1;
            soundManager.play('fireend2');
            return false;
        }

        // CZY TO BOMBA
        if(caveChar[bx+dX+(by+dY)*this.CAVE_WIDTH] == Block.CHAR_BOMBA)
        {
            //trace("bedzie bum!");
            caveChar[bx+dX + (by+dY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOMBA2+this.STOP_BIT:Block.CHAR_BOMBA2;
            //trace(caveChar[bx+liczX + (by+liczY) * CAVE_WIDTH]);
            caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_1;
            soundManager.play('fireend');
            return false;
        }

        // CZY ZNAK NIE DO ZABICIA
        if((this.getBit(bx+dX, by+dY) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_5;
            soundManager.play('fireend');
            return false;
        }

        // CZY TO ROBBO
        if((this.getBit(bx+dX, by+dY) & Block.B_ROBBO) == Block.B_ROBBO)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx+dX + (by+dY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOOM_1+this.STOP_BIT:Block.CHAR_BOOM_1;
            soundManager.play('fireend2');
            return true;
        }

        // CZY JEST COKOLWIEK
        if(this.getBit(bx+dX, by+dY) > 0)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = 0;
            caveChar[bx+dX + (by+dY) * this.CAVE_WIDTH] = bo?Block.CHAR_BOOM_1+this.STOP_BIT:Block.CHAR_BOOM_1;
            soundManager.play('fireend2');
            return false;
        }

        // PRZESUŃ POCISK
        caveChar[bx + by * this.CAVE_WIDTH] = 0;
        this.set1(bx+dX, by+dY, bo?(block+this.STOP_BIT):block);
        return false;
    }

    set1(x, y, c1){
        this.caveChar[x + y * this.CAVE_WIDTH] = c1;
    }

    /**
     * start shoot initiated by cannon or robbo, if robbo then we don't add extra STOP(STOP_BIT) bit
     * */
    startShoot(bx, by, direction, isRobboFire) {
        soundManager.play('cannon-fire');

        var stopBit = isRobboFire ? 0 : this.STOP_BIT,
            caveChar = this.caveChar,
            Block = this.block;

        //caveChar = cave.caveChar;
        if((this.getBit(bx, by) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            return false;

        // CZY TO BOMBA
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BOMBA)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOMBA2;
            return false;
        }

        // CZY TO NIESPODZIANKA
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_SURPRISE)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_SURPRISE_BOOM1;
            return false;
        }

        // CZY TO ROBBO
        if((this.getBit(bx, by) & Block.B_ROBBO) == Block.B_ROBBO)
        {
            if(direction == Block.CHAR_PROJECTILE_DOWN_A ||
                direction == Block.CHAR_PROJECTILE_RIGHT_A)
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_1+stopBit;
            else
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_1;
            return true;
        }

        // CZY JEST COKOLWIEK
        if(this.getBit(bx, by) > 0)
        {
            if(direction == Block.CHAR_PROJECTILE_DOWN_A ||
                direction == Block.CHAR_PROJECTILE_RIGHT_A)
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_1+stopBit;
            else
                caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOOM_1;
            return false;
        }

        // NIC NIE MA, WSTAWIAMY POCISK
        switch(direction)
        {
            case Block.CHAR_PROJECTILE_DOWN_A:
                this.set1(bx, by, (Block.CHAR_PROJECTILE_DOWN_A + stopBit));
                break;
            case Block.CHAR_PROJECTILE_UP_A:
                this.set1(bx, by, Block.CHAR_PROJECTILE_UP_A);
                break;
            case Block.CHAR_PROJECTILE_LEFT_A:
                this.set1(bx, by, Block.CHAR_PROJECTILE_LEFT_A);
                break;
            case Block.CHAR_PROJECTILE_RIGHT_A:
                this.set1(bx, by,(Block.CHAR_PROJECTILE_RIGHT_A + stopBit));
                break;

            // POCISKI CIAGŁE
            case Block.CHAR_LONG_PROJECTILE_DOWN_A:
                this.set1(bx, by, (Block.CHAR_LONG_PROJECTILE_DOWN_A+this.STOP_BIT));
                break;
            case Block.CHAR_LONG_PROJECTILE_UP_A:
                this.set1(bx, by, Block.CHAR_LONG_PROJECTILE_UP_A);
                break;
            case Block.CHAR_LONG_PROJECTILE_LEFT_A:
                this.set1(bx, by, Block.CHAR_LONG_PROJECTILE_LEFT_A);
                break;
            case Block.CHAR_LONG_PROJECTILE_RIGHT_A:
                this.set1(bx, by, (Block.CHAR_LONG_PROJECTILE_RIGHT_A+this.STOP_BIT));
                break;
        }
        return false;
    }

    fireBlast(bx, by, direction){

        var caveChar = this.caveChar,
            Block = this.block;

        //caveChar = cave.caveChar;
        if((this.getBit(bx, by) & Block.B_CANT_KILL) == Block.B_CANT_KILL)
            return false;

        // CZY TO BOMBA
        if(caveChar[bx+by*this.CAVE_WIDTH] == Block.CHAR_BOMBA)
        {
            caveChar[bx + by * this.CAVE_WIDTH] = Block.CHAR_BOMBA2;
            return false;
        }

        // CZY TO ROBBO
        if((this.getBit(bx, by) & Block.B_ROBBO) == Block.B_ROBBO)
        {
            switch(direction)
            {
                case Block.CHAR_BLAST_PROJECTILE_DOWN:
                    this.set1(bx, by, (Block.CHAR_BLAST_PROJECTILE_DOWN + this.STOP_BIT));
                    break;
                case Block.CHAR_BLAST_PROJECTILE_RIGHT:
                    this.set1(bx, by, (Block.CHAR_BLAST_PROJECTILE_RIGHT + this.STOP_BIT));
                    break;
                default:
                    this.set1(bx, by, direction);
                    break;
            }
            return true;
        }

        // NIC NIE MA, WSTAWIAMY POCISK
        switch(direction)
        {
            case Block.CHAR_BLAST_PROJECTILE_DOWN:
                this.set1(bx, by, (Block.CHAR_BLAST_PROJECTILE_DOWN + this.STOP_BIT));
                break;
            case Block.CHAR_BLAST_PROJECTILE_RIGHT:
                this.set1(bx, by, (Block.CHAR_BLAST_PROJECTILE_RIGHT + this.STOP_BIT));
                break;
            default:
                this.set1(bx, by, direction);
                break;
        }
        return false;
    }
}


