import { randRange } from "./robboGame";
import { GameObject } from "./game-object";

export class RobboBlock extends GameObject {

    name = "Block";

    // LET BITS MEANS SOMETHING
    B_ROBBO 		= 1;    // ROBBO
    B_CANT_KILL	    = 2;    // NIE MOŻE ZGINĄĆ OD STRZAŁU
    B_CANT_BOOM	    = 4;    // NIE MOŻE ZGINĄĆ OD WYBUCHU
    B_CAN_PUSH		= 8;    // MOŻNA PCHAĆ
    B_BIRD			= 16;   // PTAK
    B_SMTHG       	= 32;   // ZNACZNIK UZYWANY DO RÓŻNYCH CELÓW
    B_PROJECTILE  	= 64;   // LECĄCY POCISK
    B_BOX_SLIDE	    = 128;	// ŚLIZGAJĄCA KRATKA
    B_CANNON   	    = 256;  // DZIAŁKO
    B_MON_RIGHT 	= 512;  // POTWOREK PRAWOSTRONNY
    B_MON_LEFT  	= 1024; // POTWOREK LEWOSTRONNY
    B_MIRROR    	= 2048; // LUSTRO
    B_CANT_KBOM	    = 2 | 4; //this.B_CANT_KILL|this.B_CANT_BOOM,  // ROBBO

    STOP_BIT		= 1073741824; // = 2^30


    // BLOCK INDEX IN LEVEL
    CHAR_ROBBO_LEFT_A       = 1;
    CHAR_ROBBO_LEFT_B       = 80;
    CHAR_ROBBO_RIGHT_A      = 82;
    CHAR_ROBBO_RIGHT_B      = 84;
    CHAR_ROBBO_UP_A         = 86;
    CHAR_ROBBO_UP_B         = 87;
    CHAR_ROBBO_DOWN_A       = 89;
    CHAR_ROBBO_DOWN_B       = 91;

    CHAR_BOX			    = 2;
    CHAR_WALL 			    = 3;
    CHAR_SCREW       	    = 4;
    CHAR_SHIP_A     	    = 5;
    CHAR_SHIP_BLINK         = 104;
    CHAR_BULLET             = 6;
    CHAR_KEY			    = 12;
    CHAR_DOOR			    = 13;
    CHAR_GROUND			    = 14;

    CHAR_BIRD_LEFT_A        = 7;
    CHAR_BIRD_RIGHT_A       = 8;
    CHAR_BIRD_DOWN_A        = 10;
    CHAR_BIRD_UP_A          = 11;

    CHAR_BOOM_1             = 9;
    CHAR_BOOM_2             = 94;
    CHAR_BOOM_3             = 95;
    CHAR_BOOM_4             = 96;
    CHAR_BOOM_5             = 97;
    CHAR_BOOM_6             = 98;
    CHAR_BOOM_7             = 99;

    CHAR_PROJECTILE_RIGHT_A	= 15;
    CHAR_PROJECTILE_LEFT_A 	= 16;
    CHAR_PROJECTILE_UP_A   	= 17;
    CHAR_PROJECTILE_DOWN_A 	= 18;

    CHAR_SLIDE_BOX          = 19;
    CHAR_SLIDE_BOX_LEFT     = 76;
    CHAR_SLIDE_BOX_RIGHT    = 77;
    CHAR_SLIDE_BOX_UP       = 78;
    CHAR_SLIDE_BOX_DOWN     = 79;

    CHAR_BOMBA              = 20;
    CHAR_BOMBA2             = 93;

    CHAR_CANNON_LEFT        = 21;
    CHAR_CANNON_RIGHT       = 22;
    CHAR_CANNON_UP          = 23;
    CHAR_CANNON_DOWN        = 24;

    CHAR_MR_RIGHT_A         = 25;
    CHAR_MR_UP_A            = 81;
    CHAR_MR_LEFT_A          = 83;
    CHAR_MR_DOWN_A          = 85;

    CHAR_ML_RIGHT_A         = 26;
    CHAR_ML_UP_A            = 88;
    CHAR_ML_LEFT_A          = 90;
    CHAR_ML_DOWN_A          = 92;

    CHAR_SURPRISE		    = 27;
    CHAR_SURPRISE_BOOM1	    = 70;
    CHAR_SURPRISE_BOOM2	    = 71;
    CHAR_SURPRISE_BOOM3	    = 72;

    CHAR_MAGNEZ_LEFT	    = 28;
    CHAR_MAGNEZ_RIGHT	    = 29;
    CHAR_MAG_ROBBO_L	    = 73;
    CHAR_MAG_ROBBO_R	    = 74;

    CHAR_NIC			    = 30;

    CHAR_FIRE_BIRD_LEFT_A	= 31;
    CHAR_FIRE_BIRD_RIGHT_A	= 32;

    CHAR_ROTATE_CANNON_RIGHT = 33;
    CHAR_ROTATE_CANNON_LEFT  = 60;
    CHAR_ROTATE_CANNON_UP    = 61;
    CHAR_ROTATE_CANNON_DOWN  = 62;
    CHAR_MOVING_CANNON_L_A   = 34;
    CHAR_MOVING_CANNON_R_A   = 63;

    CHAR_LONG_CANNON_LEFT   = 35;
    CHAR_LONG_CANNON_RIGHT  = 36;
    CHAR_LONG_CANNON_UP     = 37;
    CHAR_LONG_CANNON_DOWN   = 38;

    CHAR_LONG_PROJECTILE_RIGHT_A = 50;
    CHAR_LONG_PROJECTILE_RIGHT_B = 51;
    CHAR_LONG_PROJECTILE_LEFT_A  = 52;
    CHAR_LONG_PROJECTILE_LEFT_B  = 53;
    CHAR_LONG_PROJECTILE_UP_A    = 54;
    CHAR_LONG_PROJECTILE_UP_B    = 55;
    CHAR_LONG_PROJECTILE_DOWN_A  = 56;
    CHAR_LONG_PROJECTILE_DOWN_B  = 57;

    CHAR_BLAST_CANNON_LEFT      = 39;
    CHAR_BLAST_CANNON_RIGHT     = 40;
    CHAR_BLAST_CANNON_UP        = 41;
    CHAR_BLAST_CANNON_DOWN      = 42;

    CHAR_BLAST_PROJECTILE_RIGHT  = 58;
    CHAR_BLAST_PROJECTILE_LEFT   = 59;
    CHAR_BLAST_PROJECTILE_UP     = 68;
    CHAR_BLAST_PROJECTILE_DOWN   = 75;

    CHAR_MIRROR_1_A             = 43;
    CHAR_MIRROR_2_A             = 44;
    CHAR_MIRROR_3_A             = 45;
    CHAR_MIRROR_4_A             = 46;
    CHAR_MIRROR_5_A             = 47;
    CHAR_MIRROR_6_A             = 48;
    CHAR_MIRROR_7_A             = 64;
    CHAR_MIRROR_8_A             = 65;
    CHAR_MIRROR_9_A             = 66;
    CHAR_MIRROR_10_A            = 67;
    CHAR_MOVING_EYES_A          = 69;
    CHAR_ADD_LIVE               = 49;

    CHAR_TELE_BOOM1             = 100;
    CHAR_TELE_BOOM2             = 101;
    CHAR_TELE_BOOM3             = 102;
    CHAR_TELE_BOOM4             = 103;


    // TILE PLACE ON TILE SET
    i_EMPTY                     = 0;
    i_BOX    	                = 1;
    i_WALL                      = 2;
    i_ELEMENT                   = 3;
    i_SHIP_A                    = 4;
    i_BULLET                    = 5;
    i_BIRD_A                    = 6;
    i_BIRD_B                    = 7;
    i_GROUND                    = 8;
    i_SHIP_B                    = 9;
    i_ROBBO_UP_A                = 10;
    i_ROBBO_UP_B                = 11;
    i_ROBBO_RIGHT_A             = 12;
    i_ROBBO_RIGHT_B             = 13;
    i_ROBBO_LEFT_A              = 14;
    i_ROBBO_LEFT_B              = 15;
    i_ROBBO_DOWN_A              = 16;
    i_ROBBO_DOWN_B              = 17;
    i_BIRD_FIRE_A               = 18;
    i_BIRD_FIRE_B               = 19;
    i_PROJECTILE_UP_A           = 20;
    i_PROJECTILE_UP_B           = 21;
    i_PROJECTILE_RIGHT_A        = 22;
    i_PROJECTILE_RIGHT_B        = 23;
    i_PROJECTILE_LEFT_A         = 24;
    i_PROJECTILE_LEFT_B         = 25;
    i_PROJECTILE_DOWN_A         = 26;
    i_PROJECTILE_DOWN_B         = 27;
    i_EYES_A                    = 28;
    i_EYES_B                    = 29;
    i_SURPRISE                  = 30;
    i_BLACK                     = 31;
    i_M_RIGHT_A                 = 32;
    i_M_RIGHT_B                 = 33;
    i_M_LEFT_A                  = 34;
    i_M_LEFT_B                  = 35;
    i_MAGNEZ_RIGHT              = 36;
    i_MAGNEZ_LEFT               = 37;
    i_MIRROR_A                  = 38;
    i_MIRROR_B                  = 39;
    i_KEY                       = 40;
    i_CANNON_UP                 = 41;
    i_CANNON_RIGHT              = 42;
    i_CANNON_MOVE               = 43;
    i_CANNON_LEFT               = 44;
    i_CANNON_DOWN               = 45;
    i_DOOR                      = 46;
    i_BOOM_4                    = 47;
    i_BOOM_3                    = 48;
    i_BOOM_2                    = 49;
    i_BOOM_1                    = 50;
    i_BOMB                      = 51;
    i_LIVE                      = 52;
    i_SLIDE_BOX                 = 53;
    i_WALL2					    = 56;

    blocksMask              = [];
    blocksImages            = [];   // initial array for current Blocks img index = image index, block index
    swapImages              = [];   // swap img array = block index, (img index 1, img index 2)
    currentBlocksImgIndex   = [];   // for every block is keeped image index, currentBlocksImgIndex[block] = imageIndex;

    constructor() {
        super();
        this.blocksMask = [
            0,                                      // 0)
            this.B_ROBBO,                           // 1) (A) CHAR_ROBBO_LEFT_A
            this.B_CAN_PUSH|this.B_CANT_KILL,       // 2) (B) CHAR_KRATKA
            this.B_CANT_KBOM,                       // 3) (C) CHAR_SCIANA
            this.B_CANT_KILL,                       // 4) (D) CHAR_SROBKA
            this.B_CANT_KBOM|this.B_CAN_PUSH,       // 5) (E) CHAR_STATEK
            this.B_SMTHG,                           // 6) (F) CHAR_NABOJ
            this.B_BIRD,                            // 7) (G) CHAR_PTAK_LEFT_A
            this.B_BIRD,                            // 8) (H) CHAR_PTAK_RIGHT_A
            this.B_CANT_KILL,                       // 9) (I) CHAR_BOOM1
            this.B_BIRD,                            //10) (J) CHAR_PTAK_DOWN_A
            this.B_BIRD,                            //11) (K) CHAR_PTAK_UP_A
            this.B_CANT_KILL,                       //12) (L) CHAR_KLUCZ
            this.B_CANT_KILL,                       //13) (M) CHAR_DRZWI
            this.B_SMTHG,                           //14) (N) CHAR_ZIEMIA
            this.B_CANT_KBOM|this.B_PROJECTILE,     //15) (O) CHAR_POCISK_RIGHT_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //16) (P) CHAR_POCISK_LEFT_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //17) (Q) CHAR_POCISK_UP_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //18) (R) CHAR_POCISK_DOWN_A
            this.B_CANT_KILL|this.B_CAN_PUSH,       //19) (S) CHAR_KRATKA_SLIZG
            this.B_CAN_PUSH,                        //20) (T) CHAR_BOMBA
            this.B_CANT_KILL|this.B_CANNON,         //21) (U) CHAR_DZIALKO_LEFT
            this.B_CANT_KILL|this.B_CANNON,         //22) (V) CHAR_DZIALKO_RIGHT
            this.B_CANT_KILL|this.B_CANNON,         //23) (W) CHAR_DZIALKO_UP
            this.B_CANT_KILL|this.B_CANNON,         //24) (X) CHAR_DZIALKO_DOWN
            this.B_MON_RIGHT,                       //25) (Y) CHAR_MR_RIGHT_A
            this.B_MON_LEFT,                        //26) (Z) CHAR_ML_RIGHT_A
            this.B_CAN_PUSH,                        //27) ([) CHAR_NIESPODZIANKA
            this.B_CANT_KBOM,                       //28) (\) CHAR_MAGNEZ_LEFT
            this.B_CANT_KBOM,                       //29) (]) CHAR_MAGNEZ_RIGHT
            this.B_CANT_KBOM,                       //30) (^) CHAR_NIC
            this.B_BIRD,                            //31) (_) CHAR_PTAK_FIRE_LEFT_A
            this.B_BIRD,                            //32) (`) CHAR_PTAK_FIRE_RIGHT_A
            this.B_CANT_KILL|this.B_CANNON,         //33) (a) CHAR_DZIALO_OBROT_RIGHT
            this.B_CANT_KILL|this.B_CANNON|this.B_CAN_PUSH,//34) (b) CHAR_DZIALKO_MOVE_L_A
            this.B_CANT_KILL|this.B_CANNON,         //35) (c) CHAR_DZIALKO_CIAG_LEFT
            this.B_CANT_KILL|this.B_CANNON,         //36) (d) CHAR_DZIALKO_CIAG_RIGHT
            this.B_CANT_KILL|this.B_CANNON,         //37) (e) CHAR_DZIALKO_CIAG_UP
            this.B_CANT_KILL|this.B_CANNON,         //38) (f) CHAR_DZIALKO_CIAG_DOWN
            this.B_CANT_KILL|this.B_CANNON,         //39) (g) CHAR_DZIALKO_BLAST_LEFT
            this.B_CANT_KILL|this.B_CANNON,         //40) (h) CHAR_DZIALKO_BLAST_RIGHT
            this.B_CANT_KILL|this.B_CANNON,         //41) (i) CHAR_DZIALKO_BLAST_UP
            this.B_CANT_KILL|this.B_CANNON,         //42) (j) CHAR_DZIALKO_BLAST_DOWN
            this.B_CANT_KILL|this.B_MIRROR,         //43) (k) CHAR_LUSTRO_1_A
            this.B_CANT_KILL|this.B_MIRROR,         //44) (l) CHAR_LUSTRO_2_A
            this.B_CANT_KILL|this.B_MIRROR,         //45) (m) CHAR_LUSTRO_3_A
            this.B_CANT_KILL|this.B_MIRROR,         //46) (n) CHAR_LUSTRO_4_A
            this.B_CANT_KILL|this.B_MIRROR,         //47) (o) CHAR_LUSTRO_5_A
            this.B_CANT_KILL|this.B_MIRROR,         //48) (p) CHAR_LUSTRO_6_A
            this.B_SMTHG,                           //49) (q) CHAR_ZYCIE
            this.B_CANT_KBOM|this.B_PROJECTILE,     //50) CHAR_POCISK_CIAGLY_RIGHT_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //51) CHAR_POCISK_CIAGLY_RIGHT_B
            this.B_CANT_KBOM|this.B_PROJECTILE,     //52) CHAR_POCISK_CIAGLY_LEFT_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //53) CHAR_POCISK_CIAGLY_LEFT_B
            this.B_CANT_KBOM|this.B_PROJECTILE,     //54) CHAR_POCISK_CIAGLY_UP_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //55) CHAR_POCISK_CIAGLY_UP_B
            this.B_CANT_KBOM|this.B_PROJECTILE,     //56) CHAR_POCISK_CIAGLY_DOWN_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //57) CHAR_POCISK_CIAGLY_DOWN_B
            this.B_CANT_KBOM|this.B_PROJECTILE,     //58) CHAR_POCISK_BLAST_RIGHT
            this.B_CANT_KBOM|this.B_PROJECTILE,     //59) CHAR_POCISK_BLAST_LEFT
            this.B_CANT_KILL|this.B_CANNON,         //60) CHAR_DZIALO_OBROT_LEFT
            this.B_CANT_KILL|this.B_CANNON,         //61) CHAR_DZIALO_OBROT_UP
            this.B_CANT_KILL|this.B_CANNON,         //62) CHAR_DZIALO_OBROT_DOWN
            this.B_CANT_KILL|this.B_CANNON|this.B_CAN_PUSH,//63) CHAR_DZIALKO_MOVE_R_A
            this.B_CANT_KILL|this.B_MIRROR,         //64) CHAR_LUSTRO_7_A
            this.B_CANT_KILL|this.B_MIRROR,         //65) CHAR_LUSTRO_8_A
            this.B_CANT_KILL|this.B_MIRROR,         //66) CHAR_LUSTRO_9_A
            this.B_CANT_KILL|this.B_MIRROR,         //67) CHAR_LUSTRO_10_A
            this.B_CANT_KBOM|this.B_PROJECTILE,     //68) CHAR_POCISK_BLAST_UP
            this.B_SMTHG,                           //69) CHAR_OCZY_A
            this.B_CANT_KBOM,                       //70) CHAR_NIESP_BOOM1
            this.B_CANT_KBOM,                       //71) CHAR_NIESP_BOOM2
            this.B_CANT_KBOM,                       //72) CHAR_NIESP_BOOM3
            this.B_ROBBO|this.B_SMTHG,              //73) CHAR_MAG_ROBBO_L
            this.B_ROBBO|this.B_SMTHG,              //74) CHAR_MAG_ROBBO_R
            this.B_CANT_KBOM|this.B_PROJECTILE,     //75) CHAR_POCISK_BLAST_DOWN
            this.B_CANT_KILL|this.B_BOX_SLIDE,      //76) CHAR_KRATKA_SLIZG_LEFT
            this.B_CANT_KILL|this.B_BOX_SLIDE,      //77) CHAR_KRATKA_SLIZG_RIGHT
            this.B_CANT_KILL|this.B_BOX_SLIDE,      //78) CHAR_KRATKA_SLIZG_UP
            this.B_CANT_KILL|this.B_BOX_SLIDE,      //79) CHAR_KRATKA_SLIZG_DOWN
            this.B_ROBBO,                           //80) CHAR_ROBBO_LEFT_B
            this.B_MON_RIGHT,                       //81) CHAR_MR_UP_A
            this.B_ROBBO,                           //82) CHAR_ROBBO_RIGHT_A
            this.B_MON_RIGHT,                       //83) CHAR_MR_LEFT_A
            this.B_ROBBO,                           //84) CHAR_ROBBO_RIGHT_B
            this.B_MON_RIGHT,                       //85) CHAR_MR_DOWN_A
            this.B_ROBBO,                           //86) CHAR_ROBBO_UP_A
            this.B_ROBBO,                           //87) CHAR_ROBBO_UP_B
            this.B_MON_LEFT,                        //88) CHAR_ML_UP_A
            this.B_ROBBO,                           //89) CHAR_ROBBO_DOWN_A
            this.B_MON_LEFT,                        //90) CHAR_ML_LEFT_A
            this.B_ROBBO,                           //91) CHAR_ROBBO_DOWN_B
            this.B_MON_LEFT,                        //92) CHAR_ML_DOWN_A
            this.B_CANT_KBOM,                       //93) CHAR_BOMBA2
            this.B_CANT_KILL,                       //94) CHAR_BOOM2
            this.B_CANT_KILL,                       //95) CHAR_BOOM3
            this.B_CANT_KILL,                       //96) CHAR_BOOM4
            this.B_CANT_KILL,                       //97) CHAR_BOOM5
            this.B_CANT_KILL,                       //98) CHAR_BOOM6
            this.B_CANT_KILL,                       //99) CHAR_BOOM7
            this.B_ROBBO,                           //100) CHAR_TELE_BOOM1
            this.B_ROBBO,                           //101) CHAR_TELE_BOOM2
            this.B_ROBBO,                           //102) CHAR_TELE_BOOM3
            this.B_ROBBO,                           //103) CHAR_TELE_BOOM4
            this.B_CANT_KBOM					    //104) CHAR_SPACE_BLINK
        ];


        // INITIAL STRUCT COUPLING BLOCK INDEX WITH IMAGE
        // image index, block index
        this.blocksImages = [
            [this.i_EMPTY, 0],
            [this.i_BOX, this.CHAR_BOX],
            [this.i_WALL, this.CHAR_WALL],
            [this.i_ELEMENT, this.CHAR_SCREW],
            [this.i_ROBBO_LEFT_A, this.CHAR_ROBBO_LEFT_A],
            [this.i_ROBBO_LEFT_B, this.CHAR_ROBBO_LEFT_B],
            [this.i_ROBBO_RIGHT_A, this.CHAR_ROBBO_RIGHT_A],
            [this.i_ROBBO_RIGHT_B, this.CHAR_ROBBO_RIGHT_B],
            [this.i_ROBBO_UP_A, this.CHAR_ROBBO_UP_A],
            [this.i_ROBBO_UP_B, this.CHAR_ROBBO_UP_B],
            [this.i_ROBBO_DOWN_A, this.CHAR_ROBBO_DOWN_A],
            [this.i_ROBBO_DOWN_B, this.CHAR_ROBBO_DOWN_B],
            [this.i_BOOM_1, this.CHAR_BOOM_1],
            [this.i_BOOM_2, this.CHAR_BOOM_2],
            [this.i_BOOM_3, this.CHAR_BOOM_3],
            [this.i_BOOM_4, this.CHAR_BOOM_4],
            [this.i_BOOM_3, this.CHAR_BOOM_5],
            [this.i_BOOM_2, this.CHAR_BOOM_6],
            [this.i_BOOM_1, this.CHAR_BOOM_7],
            [this.i_DOOR, this.CHAR_DOOR],
            [this.i_KEY, this.CHAR_KEY],
            [this.i_BULLET, this.CHAR_BULLET],
            [this.i_GROUND, this.CHAR_GROUND],
            [this.i_SLIDE_BOX, this.CHAR_SLIDE_BOX],
            [this.i_SLIDE_BOX, this.CHAR_SLIDE_BOX_LEFT],
            [this.i_SLIDE_BOX, this.CHAR_SLIDE_BOX_RIGHT],
            [this.i_SLIDE_BOX, this.CHAR_SLIDE_BOX_UP],
            [this.i_SLIDE_BOX, this.CHAR_SLIDE_BOX_DOWN],
            [this.i_BOMB, this.CHAR_BOMBA],
            [this.i_BOMB, this.CHAR_BOMBA2],
            [this.i_CANNON_LEFT, this.CHAR_CANNON_LEFT],
            [this.i_CANNON_RIGHT, this.CHAR_CANNON_RIGHT],
            [this.i_CANNON_UP, this.CHAR_CANNON_UP],
            [this.i_CANNON_DOWN, this.CHAR_CANNON_DOWN],
            [this.i_SURPRISE, this.CHAR_SURPRISE],
            [this.i_BOOM_2, this.CHAR_SURPRISE_BOOM1],
            [this.i_BOOM_4, this.CHAR_SURPRISE_BOOM2],
            [this.i_BOOM_2, this.CHAR_SURPRISE_BOOM3],
            [this.i_MAGNEZ_LEFT, this.CHAR_MAGNEZ_LEFT],
            [this.i_MAGNEZ_RIGHT, this.CHAR_MAGNEZ_RIGHT],
            [this.i_ROBBO_LEFT_A, this.CHAR_MAG_ROBBO_L],
            [this.i_ROBBO_RIGHT_A, this.CHAR_MAG_ROBBO_R],
            [this.i_BLACK, this.CHAR_NIC],
            [this.i_CANNON_RIGHT, this.CHAR_ROTATE_CANNON_RIGHT],
            [this.i_CANNON_LEFT, this.CHAR_ROTATE_CANNON_LEFT],
            [this.i_CANNON_UP, this.CHAR_ROTATE_CANNON_UP],
            [this.i_CANNON_DOWN, this.CHAR_ROTATE_CANNON_DOWN],
            [this.i_CANNON_MOVE, this.CHAR_MOVING_CANNON_L_A],
            [this.i_CANNON_MOVE, this.CHAR_MOVING_CANNON_R_A],
            [this.i_CANNON_LEFT, this.CHAR_LONG_CANNON_LEFT],
            [this.i_CANNON_RIGHT, this.CHAR_LONG_CANNON_RIGHT],
            [this.i_CANNON_UP, this.CHAR_LONG_CANNON_UP],
            [this.i_CANNON_DOWN, this.CHAR_LONG_CANNON_DOWN],
            [this.i_CANNON_LEFT, this.CHAR_BLAST_CANNON_LEFT],
            [this.i_CANNON_RIGHT, this.CHAR_BLAST_CANNON_RIGHT],
            [this.i_CANNON_UP, this.CHAR_BLAST_CANNON_UP],
            [this.i_CANNON_DOWN, this.CHAR_BLAST_CANNON_DOWN],
            [this.i_BOOM_1, this.CHAR_BLAST_PROJECTILE_RIGHT],
            [this.i_BOOM_1, this.CHAR_BLAST_PROJECTILE_LEFT],
            [this.i_BOOM_1, this.CHAR_BLAST_PROJECTILE_UP],
            [this.i_BOOM_1, this.CHAR_BLAST_PROJECTILE_DOWN],
            [this.i_LIVE, this.CHAR_ADD_LIVE],
            [this.i_BOOM_1, this.CHAR_TELE_BOOM1],
            [this.i_BOOM_2, this.CHAR_TELE_BOOM2],
            [this.i_BOOM_3, this.CHAR_TELE_BOOM3],
            [this.i_BOOM_4, this.CHAR_TELE_BOOM4],
            [this.i_SHIP_A, this.CHAR_SHIP_A]
        ];

        // swap images array
        // block index, image index A, image index B
        this.swapImages = [
            [this.CHAR_SHIP_BLINK, this.i_SHIP_B, this.i_SHIP_A],

            [this.CHAR_MR_DOWN_A, this.i_M_RIGHT_A, this.i_M_RIGHT_B],
            [this.CHAR_MR_UP_A, this.i_M_RIGHT_A, this.i_M_RIGHT_B],
            [this.CHAR_MR_LEFT_A, this.i_M_RIGHT_A, this.i_M_RIGHT_B],
            [this.CHAR_MR_RIGHT_A, this.i_M_RIGHT_A, this.i_M_RIGHT_B],

//            [this.CHAR_MR_DOWN_A, this.i_ROBBO_DOWN_A, this.i_ROBBO_DOWN_B],
//            [this.CHAR_MR_UP_A, this.i_ROBBO_UP_A, this.i_ROBBO_UP_B],
//            [this.CHAR_MR_LEFT_A, this.i_ROBBO_LEFT_A, this.i_ROBBO_LEFT_B],
//            [this.CHAR_MR_RIGHT_A, this.i_ROBBO_RIGHT_A, this.i_ROBBO_RIGHT_B],

            [this.CHAR_PROJECTILE_DOWN_A, this.i_PROJECTILE_DOWN_A, this.i_PROJECTILE_DOWN_B],
            [this.CHAR_PROJECTILE_UP_A, this.i_PROJECTILE_UP_A, this.i_PROJECTILE_UP_B],
            [this.CHAR_PROJECTILE_LEFT_A, this.i_PROJECTILE_LEFT_A, this.i_PROJECTILE_LEFT_B],
            [this.CHAR_PROJECTILE_RIGHT_A, this.i_PROJECTILE_RIGHT_A, this.i_PROJECTILE_RIGHT_B],
            [this.CHAR_BIRD_RIGHT_A, this.i_BIRD_A, this.i_BIRD_B],
            [this.CHAR_BIRD_LEFT_A, this.i_BIRD_A, this.i_BIRD_B],
            [this.CHAR_BIRD_UP_A, this.i_BIRD_A, this.i_BIRD_B],
            [this.CHAR_BIRD_DOWN_A, this.i_BIRD_A, this.i_BIRD_B],
            [this.CHAR_ML_DOWN_A, this.i_M_LEFT_A, this.i_M_LEFT_B],
            [this.CHAR_ML_UP_A, this.i_M_LEFT_A, this.i_M_LEFT_B],
            [this.CHAR_ML_LEFT_A, this.i_M_LEFT_A, this.i_M_LEFT_B],
            [this.CHAR_ML_RIGHT_A, this.i_M_LEFT_A, this.i_M_LEFT_B],
            [this.CHAR_FIRE_BIRD_LEFT_A, this.i_BIRD_FIRE_A, this.i_BIRD_FIRE_B],
            [this.CHAR_FIRE_BIRD_RIGHT_A, this.i_BIRD_FIRE_A, this.i_BIRD_FIRE_B],
            [this.CHAR_LONG_PROJECTILE_DOWN_A, this.i_PROJECTILE_DOWN_A, this.i_PROJECTILE_DOWN_B],
            [this.CHAR_LONG_PROJECTILE_UP_A, this.i_PROJECTILE_UP_A, this.i_PROJECTILE_UP_B],
            [this.CHAR_LONG_PROJECTILE_LEFT_A, this.i_PROJECTILE_LEFT_A, this.i_PROJECTILE_LEFT_B],
            [this.CHAR_LONG_PROJECTILE_RIGHT_A, this.i_PROJECTILE_RIGHT_A, this.i_PROJECTILE_RIGHT_B],
            [this.CHAR_LONG_PROJECTILE_DOWN_B, this.i_PROJECTILE_DOWN_A, this.i_PROJECTILE_DOWN_B],
            [this.CHAR_LONG_PROJECTILE_UP_B, this.i_PROJECTILE_UP_A, this.i_PROJECTILE_UP_B],
            [this.CHAR_LONG_PROJECTILE_LEFT_B, this.i_PROJECTILE_LEFT_A, this.i_PROJECTILE_LEFT_B],
            [this.CHAR_LONG_PROJECTILE_RIGHT_B, this.i_PROJECTILE_RIGHT_A, this.i_PROJECTILE_RIGHT_B],
            [this.CHAR_MIRROR_1_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_2_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_3_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_4_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_5_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_6_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_7_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_8_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_9_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MIRROR_10_A, this.i_MIRROR_A, this.i_MIRROR_B],
            [this.CHAR_MOVING_EYES_A, this.i_EYES_A, this.i_EYES_B]
        ];

        this.currentBlocksImgIndex = new Array(this.blocksMask.length);	// in game there is used blocks the same amount as in blocksMask
        for(var i=0; i<this.blocksImages.length; i++) {
            var blkImgPlace = this.blocksImages[i];
            this.currentBlocksImgIndex[blkImgPlace[1]] = blkImgPlace[0];
        }

        // set tile set and ship
        this.refreshTileSet();
    }

    tileSetIndex = false;
    refreshTileSet() {

        for(var i=0; i<this.swapImages.length;i++) {
            var swapImgArr = this.swapImages[i];
            this.currentBlocksImgIndex[swapImgArr[0]] = this.tileSetIndex ? swapImgArr[1] : swapImgArr[2];
        }
        this.tileSetIndex = !this.tileSetIndex;
    }

    setWall(wall) {
        this.currentBlocksImgIndex[this.CHAR_WALL] = this.i_WALL2 + wall;
    }

    setRandomWall() {
        this.currentBlocksImgIndex[this.CHAR_WALL] = this.i_WALL2 + randRange(0, 12);
    }
}